import React, { useContext } from 'react';
import "./adduserstyle.scss";
import { Sidebar,Nigeria } from '../../../components';
import { Breadcrumbs, Button,OutlinedInput, Link, Typography,TextField,Box,MenuItem,FormControl,Select,InputLabel,FormHelperText, CircularProgress } from '@mui/material';
import StateData from "../../../State/state.json";
import {Send} from "@mui/icons-material"
import { AuthContext } from '../../../context/authContext/AuthContext';
import axios from "axios";
import { baseUrl } from '../../../env';
import { logout } from '../../../context/authContext/AuthAction';
import { ToastContainer, toast } from 'react-toastify';
import { errorMessage, successMessage, warningMessage } from '../../../service/function';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
export default function AddUser() {
    const [fields, setFields] = React.useState({});
    const [service, setService] = React.useState([]);
    const [language, setLanguage] = React.useState([]);
    const [loading,setLoading]=React.useState(false);
    const [state,setState]=React.useState(null);
    const [jsonlga,setJsonLGA]=React.useState([]);
    const {user,isFetching,dispatch}=useContext(AuthContext);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setService(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleLanguage = (event) => {
      const {
        target: { value },
      } = event;
      setLanguage(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const changeState=(e)=>{
      setState(JSON.parse(e.target.value).name);
      setJsonLGA(JSON.parse(e.target.value).lgas);
      console.log(e);
  }

  const handleChangeInput=(e)=>{
    const value= e.target.value;
    setFields({...fields, [e.target.name]:value});
  }
  

  const sendData=async(e)=>{
    e.preventDefault();
    let token=user.jwt;
    
    try {
      fields['state']=state;
      fields['service']=service.toString();
      fields['language']=language.toString();
      if(!fields["phone"]&&!fields["email"]||!fields["name"]||!fields["state"]||!fields["role"]||!fields["gender"]){
        warningMessage(toast,"Please fill all required fields");
      return false;
      }
      setLoading(true);
      const resp=await axios.post(baseUrl+'businesses/admin/create',fields,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    if(resp.data.status==="error"){
      errorMessage(toast,resp.data.message);
      return false;
    }
    successMessage(toast,resp.data.message);
    setLoading(false);
    document.getElementById("userForm").reset();
    setFields({});
    } catch (error) {
      errorMessage(toast,error.message);
      setLoading(false)
    }
  }

  return <div>
  <Sidebar header={"NISSAGRO "}
  menu="users"
      render={
        <div>
             <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/users"
  >
    User List
  </Link>
  <Typography color="text.primary">Add User</Typography>
</Breadcrumbs>
<div>
    <h4>Add New User Information</h4>
<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
       <form id="userForm" onSubmit={e => e.preventDefault()}>
      <div>
        <TextField
          required
          name="name"
          id="outlined-required"
          label="Name"
          value={fields["name"]}
          onChange={handleChangeInput}
        />
        <TextField
        required
          id="outlined-required"
          name="phone"
          label="Mobile Number"
          type="number"
          onChange={handleChangeInput}
          defaultValue={fields["phone"]}
        />
        <TextField
        required
          id="outlined-password-input"
          name="password"
          label="Password"
          type="password"
          onChange={handleChangeInput}
        />
        <TextField
        required
          id="outlined-required"
          name="email"
          label="Email"
          defaultValue={fields["email"]}
          type="email"
          onChange={handleChangeInput}
        />
       
      </div>
      <div>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
        <Select
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Role"
          defaultValue={fields["role"]}
          name="role"
          onChange={handleChangeInput}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'farmer'}>Farmer</MenuItem>
          <MenuItem value={'rssmember'}>RSS Member</MenuItem>
          <MenuItem value={'extensionagent'}>Extention Agent</MenuItem>
          <MenuItem value={'agrodealer'}>Agro Dealer</MenuItem>
          <MenuItem value={'government'}>Government Agent</MenuItem>
          <MenuItem value={'scholarsngo'}>Scholar/NGO</MenuItem>
          <MenuItem value={'researcher'}>Researcher</MenuItem>
          <MenuItem value={'others'}>Others</MenuItem>
        </Select>
        <FormHelperText>Choose user role</FormHelperText>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
        <Select
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Gender"
          defaultValue={fields["gender"]}
          name="gender"
          onChange={handleChangeInput}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'Male'}>Male</MenuItem>
          <MenuItem value={'Female'}>Female</MenuItem>
        </Select>
        <FormHelperText>Choose user gender</FormHelperText>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Location State</InputLabel>
        <Select
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Location"
          name="state"
          onChange={changeState}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
         {StateData.map((item,i)=>(
            <MenuItem key={i} value={JSON.stringify(item)}>{item.name}</MenuItem>
         ))}
        </Select>
        <FormHelperText>Choose user location</FormHelperText>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Location LGA</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Location"
          name="lga"
          onChange={handleChangeInput}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {jsonlga.map((item,i)=>(
            <MenuItem key={i} value={item.name}>{item.name}</MenuItem>
         ))}
        </Select>
        <FormHelperText>Choose user location</FormHelperText>
      </FormControl>
      </div>
      <div>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Service</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          input={<OutlinedInput label="Name" />}
          value={service}
          MenuProps={MenuProps}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'Soil Survey'}>Soil Survey</MenuItem>
          <MenuItem value={'Soil Sampling'}>Soil Sampling</MenuItem>
          <MenuItem value={'Advisory Service'}>Advisory Service</MenuItem>
          <MenuItem value={'Technical Service'}>Technical Service</MenuItem>
          <MenuItem value={'Laboratory Service'}>Laboratory Service</MenuItem>
          <MenuItem value={'Machinery Service'}>Machinery Service</MenuItem>
        </Select>
        <FormHelperText>Choose user service</FormHelperText>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Language</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          input={<OutlinedInput label="Name" />}
          value={language}
          MenuProps={MenuProps}
          onChange={handleLanguage}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'English'}>English</MenuItem>
          <MenuItem value={'Hausa'}>Hausa</MenuItem>
          <MenuItem value={'Yoruba'}>Yoruba</MenuItem>
          <MenuItem value={'Igbo'}>Igbo</MenuItem>
        </Select>
        <FormHelperText>Choose user languages</FormHelperText>
      </FormControl>
      </div>
      <div style={{margin:20}}>
      <Button onClick={sendData} disabled={loading} variant="contained" endIcon={<Send />}>
      {!loading&& <>Submit</>}
   {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
      </form>
    </Box>
</div>
        </div>
      }
      />
  </div>;
}
