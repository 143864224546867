import React, { useContext,useEffect,useState } from 'react';
import "./addservicestyle.scss";
import { Sidebar,Nigeria } from '../../../components';
import { Breadcrumbs, Button,OutlinedInput, Link, Typography,TextField,Box,MenuItem,FormControl,Select,InputLabel,FormHelperText, CircularProgress,IconButton, Input, Autocomplete } from '@mui/material';
import StateData from "../../../State/state.json";
import {Delete, PhotoCamera, Send} from "@mui/icons-material"
import { AuthContext } from '../../../context/authContext/AuthContext';
import axios from "axios";
import { baseUrl, imageUrl } from '../../../env';
import { logout } from '../../../context/authContext/AuthAction';
import { ToastContainer, toast } from 'react-toastify';
import { errorMessage, successMessage, warningMessage } from '../../../service/function';
import moment from "moment";
import { useParams } from 'react-router-dom';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
export default function EditSoil() {
    const [fields, setFields] = React.useState({});
    const [service, setService] = React.useState([]);
    const [language, setLanguage] = React.useState([]);
    const [loading,setLoading]=React.useState(false);
    const [state,setState]=React.useState(null);
    const [category, setCategory] = React.useState([]);
    const [agrodealer, setAgroDealer] = React.useState([]);
    const [lga,setLGA]=React.useState(null);
    const [name,setName]=React.useState(null);
    const [image,setImage]=React.useState(null);
    const [selected,setSelected]=React.useState([]);
    const {user,isFetching,dispatch}=useContext(AuthContext);
  
    let { id } = useParams();


    useEffect(()=>{
        retrieveUserDetail();
    },[]);
    useEffect(()=>{
      getCategory();
    },[])

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setService(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleLanguage = (event) => {
      const {
        target: { value },
      } = event;
      setLanguage(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const getCategory=async()=>{
      let token=user.jwt;
      let resp=await axios.get(baseUrl+`product-categories`,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    let respdealer=await axios.get(baseUrl+`businesses?business_type=agrodealer`,{
      headers: {
         Authorization: `Bearer ${token}`
      }
  });
  setCategory(resp.data)
    setAgroDealer(respdealer.data);
    }

   const onEditorStateChange = (editorState,val) => {
     val(editorState);
    };

  const changeLga=(e)=>{
      setLGA(e.target.value);
  }

  const retrieveUserDetail=async()=>{
    let token=user.jwt;
    try {
        const respcrop=await axios.get(baseUrl+"products/"+id,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        setFields(respcrop.data);
        setSelected(respcrop.data?.businesses.map((item,ind)=>item.id+"-"+item.name+"-"+item.live_state));
      } catch (error) {
        
    }
}

  const handleChangeInput=(e)=>{
    const value= e.target.value;
    setFields({...fields, [e.target.name]:value});
  }
 
  const uploadImage = async(token,image) => {
    var formData = new FormData();
    setLoading(true);
    formData.append('files',image);
    const resp=await axios.post(baseUrl+"upload/",formData,{
        headers: {
           Authorization: `Bearer ${token}`,
           'Content-Type': 'multipart/form-data'
        }
    })
    return resp.data[0];
    
};

  const sendData=async(e,data)=>{
    e.preventDefault();
    let token=user.jwt;
    
    try {
        if(image){
            let imageId=await uploadImage(token,image);
            fields['image']=imageId.id
        }
      setLoading(true);
      if(selected.length>0){
      fields["businesses"]=selected.map((item)=>parseFloat(item.split("-")[0]));
      }
      const resp=await axios.put(baseUrl+'products/'+id,fields,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
  
    successMessage(toast,'Success upload');
    // document.getElementById("userForm").reset();
    setLoading(false)
    } catch (error) {
      setLoading(false);
      errorMessage(toast,error.message);
      return false;
    }
  }

  return  <div >
  <Sidebar header={"NISSAGRO "}
  menu="products"
      render={
        <div>
             <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/products"
  >
    Product List
  </Link>
  <Typography color="text.primary">Edit Product</Typography>
</Breadcrumbs>
<div>
    <h4>Edit Product</h4>
<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
       <form id="userForm" onSubmit={e => e.preventDefault()}>
       <div style={{display:'flex',flexDirection:'row'}}>
        <TextField
          required
          name="name"
          id="outlined-required"
          label="Name"
          value={fields["name"]}
          onChange={handleChangeInput}
        />
         <TextField
          name="company"
          id="outlined-required"
          label="Company"
          value={fields["company"]}
          onChange={handleChangeInput}
        />
         <TextField
         placeholder='http://example.com'
          name="url"
          id="outlined-required"
          label="Company Url"
          value={fields["url"]}
          onChange={handleChangeInput}
        />
        <TextField
        
          name="phone"
          id="outlined-required"
          label="Company Mobile Number"
          value={fields["phone"]}
          onChange={handleChangeInput}
        />
      </div>
     <div style={{display:'flex',flexDirection:'row'}}>
       {fields.image&&
       <img src={imageUrl+fields.image?.url} style={{width:100}} alt="" className="src" />}
     <label htmlFor="icon-button-file">
         <p> Choose Product image</p>
            <Input accept="image/*" onChange={e=>setImage(e.target.files[0])} id="icon-button-file" type="file" />
            <IconButton color="primary" aria-label="upload picture" component="span">
                <PhotoCamera />
            </IconButton>
            </label>
    
     <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Choose Category</InputLabel>
        <Select
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Category"
          defaultValue={fields["product_category"]}
          name="product_category"
          onChange={handleChangeInput}
        >
           
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {category.map((item,i)=>(
             <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText>Choose product category</FormHelperText>
      </FormControl>
     <TextField
          name="description"
          id="outlined-required"
          multiline={true}
          rows={4}
          label="Description"
          value={fields["description"]}
          onChange={handleChangeInput}
        />
     </div>
     <div style={{display:'flex',flexDirection:'row'}}>
     <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        // value={value}
        onChange={(event, newValue) => {
          setSelected([...selected, newValue]);
          console.log(newValue);
        }}
        // inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
        //  console.log(newInputValue);
        }}
        options={agrodealer.map((option) => option.id+"-"+option.name+"-"+option.live_state)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Agrodealer selling product"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
      <div style={{display:'flex',flexDirection:'row'}}>
        {selected.map((item,i)=>(
           <span style={{display:'flex',flexDirection:'row', margin:10,backgroundColor:"teal",padding:10,color:"white",alignItems:'center',justifyContent:'center'}}>{item} <Delete onClick={()=>{
            setSelected(selected.filter((it,ing) => ing != i));
            }} style={{cursor:'pointer'}} /> </span>
        ))}
      </div>
     {/* <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Choose Agro dealer selling Product</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Category"
          defaultValue={fields["product_category"]}
          name="product_category"
          onChange={handleChangeInput}
        >
           
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {agrodealer.map((item,i)=>(
             <MenuItem value={item.id}>{item.user?.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText>Choose Agro dealer selling Product</FormHelperText>
      </FormControl> */}
     </div>
      <div style={{margin:20}}>
      <Button onClick={sendData} disabled={loading} variant="contained" endIcon={<Send />}>
      {!loading&& <>Submit</>}
   {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
      </form>
    </Box>
</div>
        </div>
      }
      />
  </div>;
}

