
import React, { useContext, useEffect } from 'react';
import "./addofficestyle.scss";
import { Sidebar,Nigeria } from '../../../components';
import { Breadcrumbs, Button,OutlinedInput, Link, Typography,TextField,Box,MenuItem,FormControl,Select,InputLabel,FormHelperText, CircularProgress } from '@mui/material';
import StateData from "../../../State/state.json";
import {Send} from "@mui/icons-material"
import { AuthContext } from '../../../context/authContext/AuthContext';
import axios from "axios";
import { baseUrl } from '../../../env';
import { logout } from '../../../context/authContext/AuthAction';
import { ToastContainer, toast } from 'react-toastify';
import { errorMessage, successMessage, warningMessage } from '../../../service/function';
import moment from "moment";
import { Editor } from "react-draft-wysiwyg";
import { EditorState,convertToRaw,ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from 'react-router-dom';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
export default function EditOffice() {
    const [fields, setFields] = React.useState({});
    const [service, setService] = React.useState([]);
    const [language, setLanguage] = React.useState([]);
    const [loading,setLoading]=React.useState(false);
    const [state,setState]=React.useState([]);
    const [jsonlga,setJsonLGA]=React.useState([]);
    const [about,setAbout]=React.useState(null);
    const {user,isFetching,dispatch}=useContext(AuthContext);
    let { id } = useParams();

   useEffect(()=>{
    retrieveUserDetail();
     stateRet();
   },[])

   const stateRet=async()=>{
    let token=user.jwt;
     try {
       let resp=await axios.get(baseUrl+`states`,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    setState(resp.data);
     } catch (error) {
       
     }
   }

   const retrieveUserDetail=async()=>{
    let token=user.jwt;
    try {
        const respcrop=await axios.get(baseUrl+"offices/"+id,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        setFields(respcrop.data);
        setAbout(respcrop.data.description?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.description).contentBlocks)):null);
      } catch (error) {
        
    }
}

    const changeState=(e)=>{
      setState(JSON.parse(e.target.value).name);
      setJsonLGA(JSON.parse(e.target.value).lgas);
      console.log(e);
  }

  const onEditorStateChange = (editorState,val) => {
    val(editorState);
   };

  const handleChangeInput=(e)=>{
    const value= e.target.value;
    setFields({...fields, [e.target.name]:value});
  }

  const sendData=async(e)=>{
    e.preventDefault();
    let token=user.jwt;
    
    try {
      if(!fields["name"]||!fields["address"]||!fields["type"]||!fields["contact"]){
        warningMessage(toast,"Please fill all required fields");
      return false;
      }
      fields['description']=draftToHtml(convertToRaw(about.getCurrentContent()));
      setLoading(true);
      const resp=await axios.put(baseUrl+'offices/'+id,fields,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    successMessage(toast,"Successfully Submitted");
    // document.getElementById("userForm").reset();
    setFields({});
    setLoading(false);
    } catch (error) {
      errorMessage(toast,error.message);
      setLoading(false);
      return false;
    }
  }

  return <div>
  <Sidebar header={"NISSAGRO "}
  menu="office"
      render={
        <div>
             <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/offices"
  >
    Office List
  </Link>
  <Typography color="text.primary">Edit Office</Typography>
</Breadcrumbs>
<div>
    <h4>Edit Office</h4>
<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
       <form id="userForm" onSubmit={e => e.preventDefault()}>
      <div>
        <TextField
          required
          name="name"
          id="outlined-required"
          label="Title"
          value={fields["name"]}
          variant="filled"
          onChange={handleChangeInput}
        />
         <TextField
         required
          name="address"
          id="outlined-required"
          label="Address"
          value={fields["address"]}
          variant="filled"
          onChange={handleChangeInput}
        />
        <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-helper-label">Choose Type</InputLabel>
        <Select
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Audience"
          defaultValue={fields["type"]}
          name="type"
          variant="filled"
          onChange={handleChangeInput}
        >
           
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'head'}>head</MenuItem>
          <MenuItem value={'branch'}>branch</MenuItem>
          <MenuItem value={'subbranch'}>subbranch</MenuItem>
        </Select>
        <FormHelperText>Choose office type</FormHelperText>
      </FormControl>
      </div>
      <div style={{display:'flex',flexDirection:'row'}}>
      <TextField
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Phone Number"
          value={fields["contact"]}
          name="contact"
          variant="filled"
          onChange={handleChangeInput}
        />
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-helper-label">Choose State</InputLabel>
        <Select
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Audience"
          variant="filled"
          defaultValue={fields.state?.id}
          name="state"
          onChange={handleChangeInput}
        >
           
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {state.map((item,i)=>(
               <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText>Choose office state</FormHelperText>
      </FormControl>
      </div>
      <div style={{display:'flex',flexDirection:'column'}}>
     <h4>Office Information</h4>
      <Editor
  editorState={about}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAbout)}
/>
     </div>
     
      <div style={{margin:20}}>
      <Button onClick={sendData} disabled={loading} variant="contained" endIcon={<Send />}>
      {!loading&& <>Submit</>}
   {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
      </form>
    </Box>
</div>
        </div>
      }
      />
  </div>;
}
