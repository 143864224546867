import React, { useContext } from 'react';
import "./addsoilstyle.scss";
import { Sidebar,Nigeria } from '../../../components';
import { Breadcrumbs, Button,OutlinedInput, Link, Typography,TextField,Box,MenuItem,FormControl,Select,InputLabel,FormHelperText, CircularProgress } from '@mui/material';
import StateData from "../../../State/state.json";
import {Send} from "@mui/icons-material"
import { AuthContext } from '../../../context/authContext/AuthContext';
import axios from "axios";
import { baseUrl } from '../../../env';
import { logout } from '../../../context/authContext/AuthAction';
import { ToastContainer, toast } from 'react-toastify';
import { errorMessage, successMessage, warningMessage } from '../../../service/function';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
export default function AddSoil() {
    const [fields, setFields] = React.useState({});
    const [service, setService] = React.useState([]);
    const [language, setLanguage] = React.useState([]);
    const [loading,setLoading]=React.useState(false);
    const [state,setState]=React.useState(null);
    const [jsonlga,setJsonLGA]=React.useState([]);
    const {user,isFetching,dispatch}=useContext(AuthContext);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setService(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleLanguage = (event) => {
      const {
        target: { value },
      } = event;
      setLanguage(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const changeState=(e)=>{
      setState(JSON.parse(e.target.value).name);
      setJsonLGA(JSON.parse(e.target.value).lgas);
      console.log(e);
  }

  const handleChangeInput=(e)=>{
    const value= e.target.value;
    setFields({...fields, [e.target.name]:value});
  }

  const sendData=async(e)=>{
    e.preventDefault();
    let token=user.jwt;
    
    try {
      fields["type"]="livestock";
      if(!fields["name"]){
        warningMessage(toast,"Please fill all required fields");
      return false;
      }
      setLoading(true);
      const resp=await axios.post(baseUrl+'crop-libraries',fields,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    successMessage(toast,"Successfully Submitted");
    document.getElementById("userForm").reset();
    setFields({});
    setLoading(false);
    } catch (error) {
      errorMessage(toast,error.message);
      setLoading(false);
      return false;
    }
  }

  return <div>
  <Sidebar header={"NISSAGRO "}
  menu="soil-inspection"
      render={
        <div>
             <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/livestocks"
  >
    Livestock List
  </Link>
  <Typography color="text.primary">Add Livestock</Typography>
</Breadcrumbs>
<div>
    <h4>Add New Livestock Information</h4>
<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
       <form id="userForm" onSubmit={e => e.preventDefault()}>
      <div>
        <TextField
          required
          name="name"
          id="outlined-required"
          label="Name"
          value={fields["name"]}
          onChange={handleChangeInput}
        />
         <TextField
          name="description"
          id="outlined-required"
          multiline={true}
          rows={4}
          label="Description"
          value={fields["description"]}
          onChange={handleChangeInput}
        />
       
      </div>
     
     
      <div style={{margin:20}}>
      <Button onClick={sendData} disabled={loading} variant="contained" endIcon={<Send />}>
      {!loading&& <>Submit</>}
   {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
      </form>
    </Box>
</div>
        </div>
      }
      />
  </div>;
}
