import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import "./App.css";
import UserList from "./pages/user/UserList";
import UserAdd from "./pages/user/adduser/AddUser";
import UserEdit from "./pages/user/adduser/EditUser";

import CropList from "./pages/CropLibrary/CropList";
import CropAdd from "./pages/CropLibrary/addcrop/AddCrop";
import CropEdit from "./pages/CropLibrary/addcrop/CropUser";

import LivestockList from "./pages/LivestockLibrary/LivestockList";
import LivestockAdd from "./pages/LivestockLibrary/addlivestock/AddLivestock";
import LivestockEdit from "./pages/LivestockLibrary/addlivestock/LivestockUser";

import SoilList from "./pages/SoilInspection/SoilList";
import SoilAdd from "./pages/SoilInspection/addSoil/AddSoil";
import SoilEdit from "./pages/SoilInspection/addSoil/EditSoil";

import NotificationList from "./pages/Notification/NotificationList";
import NotificationAdd from "./pages/Notification/addNotification/AddNotification";
import NotificationEdit from "./pages/Notification/addNotification/EditNotification";

import ProductList from "./pages/Products/ProductList";
import ProductAdd from "./pages/Products/addProduct/AddProduct";
import ProductEdit from "./pages/Products/addProduct/EditProduct";

import RssMemberList from "./pages/RssMember/RssMemberList";
import RssMemberAdd from "./pages/RssMember/addRssMember/AddRssMember";
import RssMemberEdit from "./pages/RssMember/addRssMember/EditRssMember";

import SoilMappingList from "./pages/SoilMapping/SoilMappingList";
import SoilMappingAdd from "./pages/SoilMapping/addSoilMapping/AddSoilMapping";
import SoilMappingEdit from "./pages/SoilMapping/addSoilMapping/EditSoilMapping";

import OfficeList from "./pages/Office/OfficeList";
import OfficeAdd from "./pages/Office/addOffice/AddOffice";
import OfficeEdit from "./pages/Office/addOffice/EditOffice";

import ServiceList from "./pages/Services/ServicesList";
import ServiceAdd from "./pages/Services/addServices/AddServices";
import ServiceEdit from "./pages/Services/addServices/EditServices";

import PublicationList from "./pages/Publications/PublicationList";
import PublicationAdd from "./pages/Publications/addPublication/AddPublication";
import PublicationEdit from "./pages/Publications/addPublication/EditPublication";

import Announcement from "./pages/Announcement/Announcement";

import { BrowserRouter as Router, Route, Switch,Redirect } from "react-router-dom";
import { AuthContext } from "./context/authContext/AuthContext";
import { useContext } from "react";
const App = () => {
  const {user,isFetching,dispatch}=useContext(AuthContext);
  return (
    <Router>
        <Switch>
        <Route exact path="/login"component={() => !user?(<Login />):(<Redirect to="/" />)} />
      <Route exact path="/"component={() => user?(<Home />):(<Redirect to="/login" />)} />
      <Route exact path="/users"component={() => user?(<UserList />):(<Redirect to="/login" />)} />
      <Route exact path="/user/add"component={() => user?(<UserAdd />):(<Redirect to="/login" />)} />
      <Route exact path="/user/edit/:id"component={() => user?(<UserEdit />):(<Redirect to="/login" />)} />

      <Route exact path="/crops"component={() => user?(<CropList />):(<Redirect to="/login" />)} />
      <Route exact path="/crop/add"component={() => user?(<CropAdd />):(<Redirect to="/login" />)} />
      <Route exact path="/crop/edit/:id"component={() => user?(<CropEdit />):(<Redirect to="/login" />)} />

      <Route exact path="/livestocks"component={() => user?(<LivestockList />):(<Redirect to="/login" />)} />
      <Route exact path="/livestock/add"component={() => user?(<LivestockAdd />):(<Redirect to="/login" />)} />
      <Route exact path="/livestock/edit/:id"component={() => user?(<LivestockEdit />):(<Redirect to="/login" />)} />

      <Route exact path="/soil-inspection"component={() => user?(<SoilList />):(<Redirect to="/login" />)} />
      <Route exact path="/soil-inspection/add"component={() => user?(<SoilAdd />):(<Redirect to="/login" />)} />
      <Route exact path="/soil-inspection/edit/:id"component={() => user?(<SoilEdit />):(<Redirect to="/login" />)} />

      <Route exact path="/notification"component={() => user?(<NotificationList />):(<Redirect to="/login" />)} />
      <Route exact path="/notification/add"component={() => user?(<NotificationAdd />):(<Redirect to="/login" />)} />
      <Route exact path="/notification/edit/:id"component={() => user?(<NotificationEdit />):(<Redirect to="/login" />)} />

      <Route exact path="/products"component={() => user?(<ProductList />):(<Redirect to="/login" />)} />
      <Route exact path="/product/add"component={() => user?(<ProductAdd />):(<Redirect to="/login" />)} />
      <Route exact path="/product/edit/:id"component={() => user?(<ProductEdit />):(<Redirect to="/login" />)} />

      <Route exact path="/rss-members"component={() => user?(<RssMemberList />):(<Redirect to="/login" />)} />
      <Route exact path="/rss-member/add"component={() => user?(<RssMemberAdd />):(<Redirect to="/login" />)} />
      <Route exact path="/rss-member/edit/:id"component={() => user?(<RssMemberEdit />):(<Redirect to="/login" />)} />

      <Route exact path="/soil-mapping"component={() => user?(<SoilMappingList />):(<Redirect to="/login" />)} />
      <Route exact path="/soil-mapping/add"component={() => user?(<SoilMappingAdd />):(<Redirect to="/login" />)} />
      <Route exact path="/soil-mapping/edit/:id"component={() => user?(<SoilMappingEdit />):(<Redirect to="/login" />)} />

      <Route exact path="/offices"component={() => user?(<OfficeList />):(<Redirect to="/login" />)} />
      <Route exact path="/office/add"component={() => user?(<OfficeAdd />):(<Redirect to="/login" />)} />
      <Route exact path="/office/edit/:id"component={() => user?(<OfficeEdit />):(<Redirect to="/login" />)} />

      <Route exact path="/services"component={() => user?(<ServiceList />):(<Redirect to="/login" />)} />
      <Route exact path="/service/add"component={() => user?(<ServiceAdd />):(<Redirect to="/login" />)} />
      <Route exact path="/service/edit/:id"component={() => user?(<ServiceEdit />):(<Redirect to="/login" />)} />

      <Route exact path="/publications"component={() => user?(<PublicationList />):(<Redirect to="/login" />)} />
      <Route exact path="/publication/add"component={() => user?(<PublicationAdd />):(<Redirect to="/login" />)} />
      <Route exact path="/publication/edit/:id"component={() => user?(<PublicationEdit />):(<Redirect to="/login" />)} />

      <Route exact path="/announcement"component={() => user?(<Announcement />):(<Redirect to="/login" />)} />
       {/*<Route exact path="/series"component={() => user?(<Home type="series" />):(<Redirect to="/register" />)} />
      <Route exact path="/watch/:id"component={() => user?(<Watch />):(<Redirect to="/register" />)} />
      <Route exact path="/watch-app/:id/:token"component={() => <WatchApp />} />
      <Route exact path="/register"component={() => !user?(<Register />):(<Redirect to="/" />)} />
    */}
    </Switch>

  </Router>
  );
};

export default App;