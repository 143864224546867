import { Breadcrumbs, Button, Typography,TextField, CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AlertDialog, Sidebar } from '../../components';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useDemoData } from '@mui/x-data-grid-generator';
import { AuthContext } from '../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../env';
import axios from 'axios';
import { logout } from '../../context/authContext/AuthAction';
import { DeleteOutline, Send } from '@mui/icons-material';
import moment from "moment";
import { Link } from "react-router-dom";
import "./publicationliststyle.scss";
import { errorMessage, successMessage, warningMessage } from '../../service/function';
import { toast } from 'react-toastify';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PublicationList() {
   const [userlist,setUserList]=useState([]);
   const [category,setCategory]=useState([]);
   const [isAlert,setAlert]=useState(false);
   const [isModal,setModal]=useState(false);
   const [selectuser,setSelectUser]=useState({});
   const [loading,setLoading]=React.useState(false);
   const [fields, setFields]=useState({});
   const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    // { field: 'title' , headerName: 'Title', width: 180, renderCell: (params)=>{
    //     return (
    //         <div className="userListUser">
    //             <img className="userListImg" src={params.row.image?imageUrl+params.row.image.url:"/avatar.png"} alt="" />
               
    //         </div>
    //     )
    // }},
    { field: 'name' , headerName: 'Title', width: 250, renderCell: (params)=>{
      return (
          <div className="userListUser">
              {params.row?.name}
          </div>
      )
  }},
  { field: 'category' , headerName: 'Category', width: 180, renderCell: (params)=>{
    return (
        <div className="userListUser">
            {params.row?.publication_category?.name}
        </div>
    )
}},
{ field: 'subtitle', headerName: 'Source', width: 180 },
{ field: 'created_at' , headerName: 'Created at', width: 180, renderCell: (params)=>{
  return (
      <div className="userListUser">
          {moment(params.row?.created_at).format("LL")}
      </div>
  )
}},
    { field: 'action', headerName: 'Action', width: 150, renderCell: (params)=>{
        return (
            <>
            <Link to={{pathname:"/publication/edit/"+params.row.id, movie:params.row}} >
             <button className="userListEdit">Edit</button>
             </Link>
             <DeleteOutline className="userListDel" onClick={()=>handleDelete(params.row)}/>
            </>
        )
    } },
  ];
      const [sortModel, setSortModel] = React.useState([
        {
          field: 'id',
          sort: 'desc',
        },
      ]);
const {user,isFetching,dispatch}=useContext(AuthContext);

useEffect(()=>{
    retrieveUsers();
},[]);

const handleChangeInput=(e)=>{
  const value= e.target.value;
  setFields({...fields, [e.target.name]:value});
}

const retrieveUsers=async()=>{
    let token=user.jwt;
    try {
        const resp=await axios.get(baseUrl+"publications",{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        const respcat=await axios.get(baseUrl+"publication-categories",{
          headers: {
             Authorization: `Bearer ${token}`
          }
      });
        setUserList(resp.data);
        setCategory(respcat.data);
    } catch (error) {
        
    }
}

const handleDelete=async(item)=>{
  setSelectUser(item);

  setAlert(true);
}



const onDelete=async()=>{
  let token=user.jwt;
  try {
    const resp=await axios.delete(baseUrl+"publications/"+selectuser.id,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
  setAlert(false);
  successMessage(toast,"Successfully Deleted");
 await retrieveUsers();
} catch (error) {
  errorMessage(toast,error.message);
  setLoading(false);
  return false;
}
}

// const onDeleteCat=async(id)=>{
//   let token=user.jwt;
//   try {
//     const resp=await axios.delete(baseUrl+"publication-categories/"+id,{
//         headers: {
//            Authorization: `Bearer ${token}`
//         }
//     });
//   setAlert(false);
//   successMessage(toast,"Successfully Deleted");
//   setModal(false);
//  await retrieveUsers();
// } catch (error) {
//   errorMessage(toast,error.message);
//   setLoading(false);
//   return false;
// }
// }

const sendData=async(e)=>{
  e.preventDefault();
  let token=user.jwt;
  
  try {
    if(!fields["name"]){
      warningMessage(toast,"Please fill all required fields");
    return false;
    }
    setLoading(true);
    const resp=await axios.post(baseUrl+'publication-categories',fields,{
      headers: {
         Authorization: `Bearer ${token}`
      }
  });
  successMessage(toast,"Successfully Submitted");
  document.getElementById("userForm").reset();
  setModal(false);
  await retrieveUsers();
  setFields({});
  setLoading(false);
  } catch (error) {
    errorMessage(toast,error.message);
    setLoading(false);
    return false;
  }
}

const handleClose=()=>{
  setAlert(false);
}

  return <div className="userList">
       <Sidebar header={"NISSAGRO"}  menu="publication"
      render={
        <div>
            <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/publications"
  >
   Publications
  </Link>
  <Typography color="text.primary">List</Typography>
</Breadcrumbs>
<div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between', margin:20}}>
    <Link to="/publication/add">
<Button variant="contained">Add Publication</Button>
</Link>
<Button color="success" onClick={()=>setModal(true)} variant="contained">Add Category</Button>
</div>
<div style={{ height: 500, width: '100%' }}>
<DataGrid
        columns={columns}
        disableSelectionOnClick
        rows={userlist}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        pagination
        components={{ Toolbar: GridToolbar }}
      />
    </div>
        </div>
      }
      />
      <Modal
  open={isModal}
  onClose={()=>setModal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Publication Category
    </Typography>
    <form id="userForm" onSubmit={e => e.preventDefault()}>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        <TextField
          required
          name="name"
          id="outlined-required"
          label="Title"
          value={fields["name"]}
          onChange={handleChangeInput}
          style={{margin:10,width:300}}
        />
         <Button onClick={sendData} disabled={loading} color="success" variant="contained" endIcon={<Send />}>
      {!loading&& <>Submit</>}
   {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
        </div>
        </form>
   <div style={{display:'flex',flexDirection:'row'}}>
     {category.map((item,i)=>(
 <span key={i} style={{display:'flex', margin:5,padding:10,borderRadius:5, backgroundColor:'#1976d2',color:'white',alignItems:'center',justifyContent:'center'}} variant="contained">{item.name} </span>
     ))}
  
   </div>
  </Box>
</Modal>
    <AlertDialog  title={"Publication Information"} subtitle={"Delete Publication Information"} description={"You are about to delete Publication information, Please press agree to proceed"} openAlert={isAlert} handlePress={onDelete} handleClose={handleClose}  />
  </div>;
}
