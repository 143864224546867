import { Breadcrumbs, Button,CircularProgress, Typography,TextField,Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AlertDialog, Sidebar } from '../../components';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { AuthContext } from '../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../env';
import axios from 'axios';
import { logout } from '../../context/authContext/AuthAction';
import { DeleteOutline,Send } from '@mui/icons-material';
import moment from "moment";
import { Link } from "react-router-dom";
import "./notificationliststyle.scss";
import { errorMessage, successMessage, warningMessage } from '../../service/function';
import { toast } from 'react-toastify';

const VISIBLE_FIELDS = ['name', 'email', 'state', 'dateCreated', 'type'];

export default function NotificationList() {
   const [userlist,setUserList]=useState({});
   const [isAlert,setAlert]=useState(false);
   const [selectuser,setSelectUser]=useState({});
   const [loading,setLoading]=React.useState(false);
   const [fields, setFields] = React.useState({});

   const handleChangeInput=(e)=>{
    const value= e.target.value;
    setFields({...fields, [e.target.name]:value});
  }
const {user,isFetching,dispatch}=useContext(AuthContext);

useEffect(()=>{
    retrieveUsers();
},[])

const retrieveUsers=async()=>{
    let token=user.jwt;
    try {
        const resp=await axios.get(baseUrl+"announcement",{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        setUserList(resp.data);
    } catch (error) {
        
    }
}

const handleDelete=async(item)=>{
  setSelectUser(item);

  setAlert(true);
}

const onDelete=async()=>{
  let token=user.jwt;
  try {
    const resp=await axios.delete(baseUrl+"notifications/"+selectuser.id,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
  setAlert(false);
  successMessage(toast,"Successfully Deleted");
 await retrieveUsers();
} catch (error) {
  errorMessage(toast,error.message);
  setLoading(false);
  return false;
}
}

const sendData=async(e)=>{
  e.preventDefault();
  let token=user.jwt;
  
  try {
    if(!fields["description"]){
      warningMessage(toast,"Please fill all required field");
    return false;
    }
    setLoading(true);
    const resp=await axios.put(baseUrl+'announcement',fields,{
      headers: {
         Authorization: `Bearer ${token}`
      }
  });
  successMessage(toast,"Successfully Submitted");
  setLoading(false);
  } catch (error) {
    errorMessage(toast,error.message);
    setLoading(false);
    return false;
  }
}

const handleClose=()=>{
  setAlert(false);
}

  return <div className="userList">
       <Sidebar header={"NISSAGRO"}  menu="announcement"
      render={
        <div>
            <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Typography color="text.primary">Announcement</Typography>
</Breadcrumbs>

<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
       <form id="userForm" onSubmit={e => e.preventDefault()}>
      <div>
<TextField
          name="description"
          id="outlined-required"
          multiline={true}
          rows={4}
          label="Description"
          value={fields["description"]}
          onChange={handleChangeInput}
        />
    </div>
    <div style={{margin:20}}>
      <Button onClick={sendData} disabled={loading} variant="contained" endIcon={<Send />}>
      {!loading&& <>Submit</>}
   {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
      </form>
    </Box>
    </div>
      }
      />
    <AlertDialog  title={"Notification Information"} subtitle={"Delete Notification Information"} description={"You are about to delete a Notification information, Please press agree to proceed"} openAlert={isAlert} handlePress={onDelete} handleClose={handleClose}  />
  </div>;
}
