import React, { useContext, useState } from 'react';
import { login } from '../../context/authContext/apiCalls';
import { AuthContext } from '../../context/authContext/AuthContext';
import {CircularProgress} from '@mui/material';
import "./loginstyle.scss";

export default function Login() {
    const [getEmail, setEmail] = useState("");
    const [getPassword, setPassword] = useState("");

    const {isFetching,dispatch}=useContext(AuthContext);
   
   
    const LoginUser=async()=>{
        try {
            let resp=  await login({identifier:getEmail,password:getPassword},dispatch);
           console.log(resp)
        } catch (error) {
           console.log(error) 
        }
     
    //   console.log('seyi')
      }
  return (
  <div className='login'> 
    <div className="container">
        <div className="logo">
            <img src="/logo.png" alt="" />
            <h3 className="headerText">NISSAGRO</h3>
        </div>
        <div className="form">
            <h3>Administrator Control Panel</h3>
            <input type="text" onChange={(e)=>setEmail(e.target.value)} placeholder='Email Address' name="" id="" />
            <input type="password" onChange={(e)=>setPassword(e.target.value)} placeholder='Password' name="" id="" />
            <button onClick={LoginUser}  className="submit">
            {!isFetching&&
                <b> Login </b>}
                {isFetching&&<>
                <CircularProgress color="inherit" size={20}  />
                <b>...a moment</b>
                </>}
            </button>
        </div>
    </div>
  </div>
  );
}
