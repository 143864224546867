import React from 'react';
import { Sidebar,Nigeria } from '../../components';
import { ReactSVG } from 'react-svg'
import { Grid,List,ListItem, ListItemText,ListItemAvatar,Avatar,Divider, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';


export default function Home() {
  const data = [
    {
      name: 'Farmers',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'RSS Members',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Agro Dealers',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Extension Agent',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Government',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Scholars/Ngo',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Others',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  return <div>
      <Sidebar header={"NISSAGRO Admin"}
       menu="dashboard"
      render={
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <ResponsiveContainer width={1000} height={300}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
        </Grid>
        <Grid item xs={4}>
        <Typography variant="h5" component="h2">
          Top Users
      </Typography>
          <div style={{backgroundColor:'rgb(239 245 241)',padding:20}}>
     
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Photos" secondary="Jan 9, 2014" />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Photos" secondary="Jan 9, 2014" />
      </ListItem>
      <Divider variant="inset" component="li" />
     
    </List>
    </div>
        </Grid>
        <Grid item xs={4}>
        <Typography variant="h5" component="h2">
          Top Conversation
      </Typography>
          <div style={{backgroundColor:'rgb(239 245 241)',padding:20}}>
     
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Photos" secondary="Jan 9, 2014" />
      </ListItem>
      <Divider variant="inset" component="li" />
     
    </List>
    </div>
        </Grid>
        <Grid item xs={4}>
        <Typography variant="h5" component="h2">
          Top Soil Scientist
      </Typography>
          <div style={{backgroundColor:'rgb(239 245 241)',padding:20}}>
     
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Photos" secondary="Jan 9, 2014" />
      </ListItem>
      <Divider variant="inset" component="li" />
     
    </List>
    </div>
        </Grid>
      </Grid>
      }
      />
  </div>;
}
