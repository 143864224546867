import React, { useContext, useEffect } from 'react';
import "./addcropstyle.scss";
import { Sidebar,Nigeria } from '../../../components';
import { Breadcrumbs, Button,OutlinedInput, Link, Typography,TextField,Box,option,FormControl,NativeSelect,InputLabel,FormHelperText, IconButton, Input, CircularProgress } from '@mui/material';
import StateData from "../../../State/state.json";
import {PhotoCamera, Send} from "@mui/icons-material"
import { AuthContext } from '../../../context/authContext/AuthContext';
import axios from "axios";
import { baseUrl } from '../../../env';
import { logout } from '../../../context/authContext/AuthAction';
import { ToastContainer, toast } from 'react-toastify';
import { errorMessage, successMessage, warningMessage } from '../../../service/function';
import { useParams } from 'react-router-dom';
import { Editor } from "react-draft-wysiwyg";
import { EditorState,convertToRaw,ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
export default function AddUser() {
    const [fields, setFields] = React.useState({});
    const [service, setService] = React.useState([]);
    const [language, setLanguage] = React.useState([]);
    const [loading,setLoading]=React.useState(false);
    const [state,setState]=React.useState(null);
    const [lga,setLGA]=React.useState(null);
    const [name,setName]=React.useState(null);
    const [image,setImage]=React.useState(null);
    const {user,isFetching,dispatch}=useContext(AuthContext);
    const [about,setAbout]=React.useState(null);
    const [aboutYor,setAboutYor]=React.useState(null);
    const [aboutHau,setAboutHau]=React.useState(null);
    const [aboutIgb,setAboutIgb]=React.useState(null);
    const [attack,setAttack]=React.useState(null);
    const [attackYor,setAttackYor]=React.useState(null);
    const [attackHau,setAttackHau]=React.useState(null);
    const [attackIgb,setAttackIgb]=React.useState(null);
    const [deficiency,setDeficiency]=React.useState(null);
    const [deficiencyYor,setDeficiencyYor]=React.useState(null);
    const [deficiencyHau,setDeficiencyHau]=React.useState(null);
    const [deficiencyIgb,setDeficiencyIgb]=React.useState(null);
    const [advice,setAdvice]=React.useState(null);
    const [adviceYor,setAdviceYor]=React.useState(null);
    const [adviceHau,setAdviceHau]=React.useState(null);
    const [adviceIgb,setAdviceIgb]=React.useState(null)
    let { id } = useParams();


    useEffect(()=>{
        retrieveUserDetail();
    },[]);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setService(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleLanguage = (event) => {
      const {
        target: { value },
      } = event;
      setLanguage(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

   const onEditorStateChange = (editorState,val) => {
     val(editorState);
    };

  const changeLga=(e)=>{
      setLGA(e.target.value);
  }

  const retrieveUserDetail=async()=>{
    let token=user.jwt;
    try {
        const respcrop=await axios.get(baseUrl+"crop-libraries/"+id,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        setFields(respcrop.data);
        setAbout(respcrop.data.about?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.about).contentBlocks)):null);
        setAboutYor(respcrop.data.aboutYoruba?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.aboutYoruba).contentBlocks)):null);
        setAboutHau(respcrop.data.aboutHausa?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.aboutHausa).contentBlocks)):null);
        setAboutIgb(respcrop.data.aboutIgbo?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.aboutIgbo).contentBlocks)):null);
        setAttack(respcrop.data.about?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.attack).contentBlocks)):null);
        setAttackYor(respcrop.data.attackYoruba?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.attackYoruba).contentBlocks)):null);
        setAttackHau(respcrop.data.attackHausa?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.attackHausa).contentBlocks)):null);
        setAttackIgb(respcrop.data.attackIgbo?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.attackIgbo).contentBlocks)):null);
        setDeficiency(respcrop.data.deficiency?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.deficiency).contentBlocks)):null);
        setDeficiencyYor(respcrop.data.deficiencyYoruba?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.deficiencyYoruba).contentBlocks)):null);
        setDeficiencyHau(respcrop.data.deficiencyHausa?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.deficiencyHausa).contentBlocks)):null);
        setDeficiencyIgb(respcrop.data.deficiencyIgbo?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.deficiencyIgbo).contentBlocks)):null);
        setAdvice(respcrop.data.advice?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.advice).contentBlocks)):null);
        setAdviceYor(respcrop.data.adviceYoruba?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.adviceYoruba).contentBlocks)):null);
        setAdviceHau(respcrop.data.adviceHausa?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.adviceHausa).contentBlocks)):null);
        setAdviceIgb(respcrop.data.adviceIgbo?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.adviceIgbo).contentBlocks)):null);
        
      } catch (error) {
        
    }
}

  const handleChangeInput=(e)=>{
    const value= e.target.value;
    setFields({...fields, [e.target.name]:value});
  }
 
  const uploadImage = async(token,image) => {
    var formData = new FormData();
    setLoading(true);
    formData.append('files',image);
    try {
      const resp=await axios.post(baseUrl+"upload/",formData,{
        headers: {
           Authorization: `Bearer ${token}`,
           'Content-Type': 'multipart/form-data'
        }
    })
   
    return resp.data[0];
    } catch (error) {
      errorMessage(toast,error.message);
      setLoading(false);
    } 
};

  const sendData=async(e,data)=>{
    e.preventDefault();
    let token=user.jwt;
    
    try {
        if(image){
            let imageId=await uploadImage(token,image);
            fields['image']=imageId.id
        }
      fields[Object.keys(data)[0]]=draftToHtml(convertToRaw(data[Object.keys(data)[0]].getCurrentContent()));
      setLoading(true);
      const resp=await axios.put(baseUrl+'crop-libraries/'+id,fields,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    successMessage(toast,"Sucessfull update");
    // document.getElementById("userForm").reset();
    setLoading(false)
    } catch (error) {
      errorMessage(toast,error.message);
      setLoading(false)
    }
  }

  return  <div >
  <Sidebar header={"NISSAGRO "}
  menu="crop-library"
      render={
         <div >
             <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/crops"
  >
    Crop List
  </Link>
  <Typography color="text.primary">Edit Crop</Typography>
</Breadcrumbs>
 <div >
    <h4>Edit Crop Information</h4>
<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
       <form id="userForm" onSubmit={e => e.preventDefault()}>
       <div style={{display:'flex',flexDirection:'row'}}>
        <TextField
          required
          name="name"
          id="outlined-required"
          label="Crop Name"
          value={fields["name"]}
          onChange={handleChangeInput}
        />
        <label htmlFor="icon-button-file">
         <p> Choose crop image</p>
            <Input accept="image/*" onChange={e=>setImage(e.target.files[0])} id="icon-button-file" type="file" />
            <IconButton color="primary" aria-label="upload picture" component="span">
                <PhotoCamera />
            </IconButton>
            </label>
      </div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
           <Typography>About Crop</Typography>
           </AccordionSummary>
      <div style={{display:'flex',flexDirection:'column'}}>
      <h4>About Crop (English)</h4>
      <Editor
  editorState={about}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAbout)}
/>
<div style={{margin:20}}>
      <Button disabled={loading} onClick={(e)=>sendData(e,{'about':about})} variant="contained" endIcon={<Send />}>
      {!loading&& <> Update About Crop (English)</>}
   {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>About Crop (Hausa)</h4>
      <Editor
  editorState={aboutHau}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAboutHau)}
/>
<div style={{margin:20}}>
      <Button disabled={loading} onClick={(e)=>sendData(e,{'aboutHausa':aboutHau})} variant="contained" endIcon={<Send />}>
      {!loading&& <>Update About Crop (Hausa)</>}
 {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>About Crop (Yoruba)</h4>
      <Editor
  editorState={aboutYor}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAboutYor)}
/>
<div style={{margin:20}}>
      <Button disabled={loading} onClick={(e)=>sendData(e,{'aboutYoruba':aboutYor})} variant="contained" endIcon={<Send />}>
      {!loading&& <>Update About Crop (Yoruba)</>}
 {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>About Crop (Igbo)</h4>
      <Editor
  editorState={aboutIgb}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAboutIgb)}
/>
<div style={{margin:20}}>
      <Button disabled={loading} onClick={(e)=>sendData(e,{'aboutIgbo':aboutIgb})} variant="contained" endIcon={<Send />}>
      {!loading&& <>Update About Crop (Igbo)</>}
 {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
</div>
</Accordion>

<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header2"
        >
           <Typography>Attack Crop</Typography>
           </AccordionSummary>
      <div style={{display:'flex',flexDirection:'column'}}>
      <h4>Attack Crop (English)</h4>
      <Editor
  editorState={attack}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAttack)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'attack':attack})} variant="contained" endIcon={<Send />}>
 Update Attack Crop (English)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>Attack Crop (Hausa)</h4>
      <Editor
  editorState={attackHau}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAttackHau)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'attackHausa':attackHau})} variant="contained" endIcon={<Send />}>
 Update Attack Crop (Hausa)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>Attack Crop (Yoruba)</h4>
      <Editor
  editorState={attackYor}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAttackYor)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'attackYoruba':attackYor})} variant="contained" endIcon={<Send />}>
 Update Attack Crop (Yoruba)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>Attack Crop (Igbo)</h4>
      <Editor
  editorState={attackIgb}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAttackIgb)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'attackIgbo':attackIgb})} variant="contained" endIcon={<Send />}>
 Update Attack Crop (Igbo)
</Button>
      </div>
</div>
</Accordion>

<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header3"
        >
           <Typography>Deficiency Crop</Typography>
           </AccordionSummary>
      <div style={{display:'flex',flexDirection:'column'}}>
      <h4>Deficiency Crop (English)</h4>
      <Editor
  editorState={deficiency}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setDeficiency)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'deficiency':deficiency})} variant="contained" endIcon={<Send />}>
 Update Deficiency Crop (English)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>Deficiency Crop (Hausa)</h4>
      <Editor
  editorState={deficiencyHau}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setDeficiencyHau)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'deficiencyHausa':deficiencyHau})} variant="contained" endIcon={<Send />}>
 Update Deficiency Crop (Hausa)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>Deficiency Crop (Yoruba)</h4>
      <Editor
  editorState={deficiencyYor}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setDeficiencyYor)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'deficiencyYoruba':deficiencyYor})} variant="contained" endIcon={<Send />}>
 Update Deficiency Crop (Yoruba)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>Deficiency Crop (Igbo)</h4>
      <Editor
  editorState={deficiencyIgb}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setDeficiencyIgb)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'defiencyIgbo':deficiencyIgb})} variant="contained" endIcon={<Send />}>
 Update Deficiency Crop (Igbo)
</Button>
      </div>
</div>
</Accordion>

<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header4"
        >
           <Typography>Advice Crop</Typography>
           </AccordionSummary>
      <div style={{display:'flex',flexDirection:'column'}}>
      <h4>Advice Crop (English)</h4>
      <Editor
  editorState={advice}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAdvice)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'advice':advice})} variant="contained" endIcon={<Send />}>
 Update Advice Crop (English)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>Advice Crop (Hausa)</h4>
      <Editor
  editorState={adviceHau}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAdviceHau)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'adviceHausa':adviceHau})} variant="contained" endIcon={<Send />}>
 Update Advice Crop (Hausa)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>Advice Crop (Yoruba)</h4>
      <Editor
  editorState={adviceYor}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAdviceYor)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'adviceYoruba':adviceYor})} variant="contained" endIcon={<Send />}>
 Update Advice Crop (Yoruba)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>Advice Crop (Igbo)</h4>
      <Editor
  editorState={adviceIgb}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAdviceIgb)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'adviceIgbo':adviceIgb})} variant="contained" endIcon={<Send />}>
 Update Advice Crop (Igbo)
</Button>
      </div>
</div>
</Accordion>
     
      </form>
    </Box>
</div>
        </div>
      }
      />
  </div>;
}

