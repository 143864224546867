import React, { useContext,useEffect,useState } from 'react';
import "./addnotificationstyle.scss";
import { Sidebar,Nigeria } from '../../../components';
import { Breadcrumbs, Button,OutlinedInput, Link, Typography,TextField,Box,MenuItem,FormControl,Select,InputLabel,FormHelperText, CircularProgress } from '@mui/material';
import StateData from "../../../State/state.json";
import {Send} from "@mui/icons-material"
import { AuthContext } from '../../../context/authContext/AuthContext';
import axios from "axios";
import { baseUrl } from '../../../env';
import { logout } from '../../../context/authContext/AuthAction';
import { ToastContainer, toast } from 'react-toastify';
import { errorMessage, successMessage, warningMessage } from '../../../service/function';
import moment from "moment";
import { useParams } from 'react-router-dom';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
export default function EditSoil() {
    const [fields, setFields] = React.useState({});
    const [service, setService] = React.useState([]);
    const [language, setLanguage] = React.useState([]);
    const [loading,setLoading]=React.useState(false);
    const [state,setState]=React.useState(null);
    const [lga,setLGA]=React.useState(null);
    const [name,setName]=React.useState(null);
    const [image,setImage]=React.useState(null);
    const {user,isFetching,dispatch}=useContext(AuthContext);
  
    let { id } = useParams();


    useEffect(()=>{
        retrieveUserDetail();
    },[]);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setService(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleLanguage = (event) => {
      const {
        target: { value },
      } = event;
      setLanguage(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

   const onEditorStateChange = (editorState,val) => {
     val(editorState);
    };

  const changeLga=(e)=>{
      setLGA(e.target.value);
  }

  const retrieveUserDetail=async()=>{
    let token=user.jwt;
    try {
        const respcrop=await axios.get(baseUrl+"notifications/"+id,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        setFields(respcrop.data);
      } catch (error) {
        
    }
}

  const handleChangeInput=(e)=>{
    const value= e.target.value;
    setFields({...fields, [e.target.name]:value});
  }
 
  const uploadImage = async(token,image) => {
    var formData = new FormData();
    setLoading(true);
    formData.append('files',image);
    const resp=await axios.post(baseUrl+"upload/",formData,{
        headers: {
           Authorization: `Bearer ${token}`,
           'Content-Type': 'multipart/form-data'
        }
    })
    console.log(resp.data[0])
    return resp.data[0];
    
};

  const sendData=async(e,data)=>{
    e.preventDefault();
    let token=user.jwt;
    
    try {
        if(image){
            let imageId=await uploadImage(token,image);
            fields['image']=imageId.id
        }
      setLoading(true);
      const resp=await axios.put(baseUrl+'notifications/'+id,fields,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    if(resp.data.status==="error"){
      errorMessage(toast,resp.data.message);
      return false;
    }
    successMessage(toast,resp.data.message);
    // document.getElementById("userForm").reset();
    setLoading(false)
    } catch (error) {
      errorMessage(toast,error.message);
      return false;
    }
  }

  return  <div >
  <Sidebar header={"NISSAGRO "}
  menu="notification"
      render={
        <div>
             <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/notification"
  >
    Notification List
  </Link>
  <Typography color="text.primary">Edit Notification</Typography>
</Breadcrumbs>
<div>
    <h4>Edit Notification</h4>
<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
       <form id="userForm" onSubmit={e => e.preventDefault()}>
      <div>
        <TextField
          required
          name="name"
          id="outlined-required"
          label="Title"
          value={fields["name"]}
          onChange={handleChangeInput}
        />
         <TextField
          name="subtitle"
          id="outlined-required"
          label="Sub Title"
          value={fields["subtitle"]}
          onChange={handleChangeInput}
        />
         <TextField
          required
          name="startdate"
          id="outlined-required"
          label="Start Date"
          type={"date"}
          value={fields["startdate"]}
          onChange={handleChangeInput}
        />
        <TextField
          name="enddate"
          id="outlined-required"
          label="End Date"
          type={"date"}
          value={fields["enddate"]}
          onChange={handleChangeInput}
        />
      </div>
     <div>
     <TextField
        placeholder='http://example.com'
          name="url"
          id="outlined-required"
          label="Website Url"
          value={fields["url"]}
          onChange={handleChangeInput}
        />
         <TextField
        placeholder='http://example.com'
          name="file"
          id="outlined-required"
          label="Image"
          // value={fields["link"]}
          type={"file"}
          onChange={setImage}
        />
     <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Choose Audience</InputLabel>
        <Select
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Audience"
          defaultValue={fields["type"]}
          name="type"
          onChange={handleChangeInput}
        >
           
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'all'}>All</MenuItem>
          <MenuItem value={'farmer'}>Farmer</MenuItem>
          <MenuItem value={'rssmember'}>RSS Member</MenuItem>
          <MenuItem value={'extensionagent'}>Extention Agent</MenuItem>
          <MenuItem value={'agrodealer'}>Agro Dealer</MenuItem>
          <MenuItem value={'government'}>Government Agent</MenuItem>
          <MenuItem value={'scholarsngo'}>Scholar/NGO</MenuItem>
          <MenuItem value={'researcher'}>Researcher</MenuItem>
          <MenuItem value={'others'}>Others</MenuItem>
        </Select>
        <FormHelperText>Choose user role</FormHelperText>
      </FormControl>
     <TextField
          name="description"
          id="outlined-required"
          multiline={true}
          rows={4}
          label="Description"
          value={fields["description"]}
          onChange={handleChangeInput}
        />
     </div>
     
      <div style={{margin:20}}>
      <Button onClick={sendData} disabled={loading} variant="contained" endIcon={<Send />}>
      {!loading&& <>Submit</>}
   {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
      </form>
    </Box>
</div>
        </div>
      }
      />
  </div>;
}

