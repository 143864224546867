import React, { useContext,useEffect,useState } from 'react';
import "./addsoilmappingstyle.scss";
import { Sidebar,Nigeria } from '../../../components';
import { Breadcrumbs, Button,OutlinedInput, Link, Typography,TextField,Box,MenuItem,FormControl,Select,InputLabel,FormHelperText, CircularProgress,IconButton, Input, Autocomplete } from '@mui/material';
import StateData from "../../../State/state.json";
import {Delete, PhotoCamera, Send} from "@mui/icons-material"
import { AuthContext } from '../../../context/authContext/AuthContext';
import axios from "axios";
import { baseUrl, imageUrl } from '../../../env';
import { logout } from '../../../context/authContext/AuthAction';
import { ToastContainer, toast } from 'react-toastify';
import { errorMessage, successMessage, warningMessage } from '../../../service/function';
import moment from "moment";
import { useParams } from 'react-router-dom';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
export default function EditSoilMapping() {
    const [fields, setFields] = React.useState({});
    const [service, setService] = React.useState([]);
    const [language, setLanguage] = React.useState([]);
    const [loading,setLoading]=React.useState(false);
    const [state,setState]=React.useState(null);
    const [category, setCategory] = React.useState([]);
    const [agrodealer, setAgroDealer] = React.useState([]);
    const [lga,setLGA]=React.useState(null);
    const [name,setName]=React.useState(null);
    const [image,setImage]=React.useState(null);
    const [selected,setSelected]=React.useState([]);
    const {user,isFetching,dispatch}=useContext(AuthContext);
  
    let { id } = useParams();


    useEffect(()=>{
        retrieveUserDetail();
    },[]);
  

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setService(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleLanguage = (event) => {
      const {
        target: { value },
      } = event;
      setLanguage(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const getCategory=async()=>{
     
    }

   const onEditorStateChange = (editorState,val) => {
     val(editorState);
    };

  const changeLga=(e)=>{
      setLGA(e.target.value);
  }

  const retrieveUserDetail=async()=>{
    let token=user.jwt;
    try {
        const respcrop=await axios.get(baseUrl+"soil-mappings/"+id,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        setFields(respcrop.data);
        
      } catch (error) {
        
    }
}

  const handleChangeInput=(e)=>{
    const value= e.target.value;
    setFields({...fields, [e.target.name]:value});
  }
 
  const uploadImage = async(token,image) => {
    var formData = new FormData();
    setLoading(true);
    formData.append('files',image);
    const resp=await axios.post(baseUrl+"upload/",formData,{
        headers: {
           Authorization: `Bearer ${token}`,
           'Content-Type': 'multipart/form-data'
        }
    })
    return resp.data[0];
    
};

  const sendData=async(e,data)=>{
    e.preventDefault();
    let token=user.jwt;
    
    try {
        if(image){
            let imageId=await uploadImage(token,image);
            fields['image']=imageId.id
        }
      setLoading(true);
     fields["data"]={
      "P": fields.P?fields.P:fields.data?.P,
      "K": fields.K?fields.K:fields.data?.K,
      "PH": fields.PH?fields.PH:fields.data?.PH,
      "S": fields.S?fields.S:fields.data?.S,
      "N": fields.N?fields.N:fields.data?.N
    }
      const resp=await axios.put(baseUrl+'soil-mappings/'+id,fields,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
  
    successMessage(toast,'Success upload');
    // document.getElementById("userForm").reset();
    setLoading(false)
    } catch (error) {
      setLoading(false);
      errorMessage(toast,error.message);
      return false;
    }
  }

  return  <div >
  <Sidebar header={"NISSAGRO "}
  menu="soilmapping"
      render={
        <div>
             <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/soil-mapping"
  >
    Soil Map List
  </Link>
  <Typography color="text.primary">Edit Soil Map</Typography>
</Breadcrumbs>
<div>
    <h4>Edit Soil Map</h4>
<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
       <form id="userForm" onSubmit={e => e.preventDefault()}>
       <div style={{display:'flex',flexDirection:'row'}}>
        <TextField
          name="state"
          disabled
          id="outlined-required"
          helperText="state"
          value={fields.states?.name}
          onChange={handleChangeInput}
        />
         <TextField
          name="lga"
          disabled
          id="outlined-required"
          helperText="Region"
          value={fields.lga?.name}
          onChange={handleChangeInput}
        />
         <TextField
          name="url"
          disabled
          id="outlined-required"
          helperText="Longitude"
          value={fields?.longitude}
          onChange={handleChangeInput}
        />
        <TextField
        disabled
          name="phone"
          id="outlined-required"
          helperText="Latitude"
          value={fields?.latitude}
          onChange={handleChangeInput}
        />
      </div>
     <div style={{display:'flex',flexDirection:'row'}}>
     <TextField
          name="address"
          id="outlined-required"
          helperText="Address"
          value={fields["address"]}
          onChange={handleChangeInput}
        />
        <TextField
          name="P"
          id="outlined-required"
          helperText="Phosphorus"
          defaultValue={fields.data?.P}
          variant="filled"
          onChange={handleChangeInput}
        />
        <TextField
          name="K"
          id="outlined-required"
          helperText="Pottasium"
          defaultValue={fields.data?.K}
          variant="filled"
          onChange={handleChangeInput}
        />
         <TextField
          name="PH"
          id="outlined-required"
          helperText="PH"
          defaultValue={fields.data?.PH}
          variant="filled"
          onChange={handleChangeInput}
        />
        
        
    </div>
    <div style={{display:'flex',flexDirection:'row'}}>
    <TextField
          name="S"
          id="outlined-required"
          helperText="Sodium"
          defaultValue={fields.data?.S}
          variant="filled"
          onChange={handleChangeInput}
        />
    <TextField
          name="N"
          id="outlined-required"
          helperText="Nitrogen"
          defaultValue={fields.data?.N}
          variant="filled"
          onChange={handleChangeInput}
        />
      </div>
      <div style={{margin:20}}>
      <Button onClick={sendData} disabled={loading} variant="contained" endIcon={<Send />}>
      {!loading&& <>Submit</>}
   {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
      </form>
    </Box>
</div>
        </div>
      }
      />
  </div>;
}

