import { Breadcrumbs, Button, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AlertDialog, Sidebar } from '../../components';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { AuthContext } from '../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../env';
import axios from 'axios';
import { logout } from '../../context/authContext/AuthAction';
import { DeleteOutline } from '@mui/icons-material';
import moment from "moment";
import { Link } from "react-router-dom";
import "./serviceliststyle.scss";
import { errorMessage, successMessage } from '../../service/function';
import { toast } from 'react-toastify';

const VISIBLE_FIELDS = ['name', 'email', 'state', 'dateCreated', 'type'];

export default function ServiceList() {
   const [userlist,setUserList]=useState([]);
   const [isAlert,setAlert]=useState(false);
   const [selectuser,setSelectUser]=useState({});
   const [loading,setLoading]=React.useState(false);
   const columns = [
    { field: 'id', headerName: 'ID', width: 180 },
    { field: 'title' , headerName: 'Image', width: 180, renderCell: (params)=>{
        return (
            <div className="userListUser">
                <img className="userListImg" src={params.row.image?imageUrl+params.row.image.url:"/avatar.png"} alt="" />
               
            </div>
        )
    }},
    { field: 'name' , headerName: 'Name', width: 180, renderCell: (params)=>{
      return (
          <div className="userListUser">
              {params.row?.name}
          </div>
      )
  }},
  { field: 'type', headerName: 'Type', width: 180, renderCell: (params)=>{
    return (
        <div className="userListUser">
            {params.row?.product_category?.name}
        </div>
    )
} },
  { field: 'description' , headerName: 'Description', width: 180, renderCell: (params)=>{
    return (
        <div className="userListUser">
            {params.row?.description}
        </div>
    )
}},

    { field: 'action', headerName: 'Action', width: 150, renderCell: (params)=>{
        return (
            <>
            <Link to={{pathname:"/product/edit/"+params.row.id, movie:params.row}} >
             <button className="userListEdit">Edit</button>
             </Link>
             <DeleteOutline className="userListDel" onClick={()=>handleDelete(params.row)}/>
            </>
        )
    } },
  ];
      const [sortModel, setSortModel] = React.useState([
        {
          field: 'id',
          sort: 'desc',
        },
      ]);
const {user,isFetching,dispatch}=useContext(AuthContext);

useEffect(()=>{
    // retrieveUsers();
},[])

const retrieveUsers=async()=>{
    let token=user.jwt;
    try {
        const resp=await axios.get(baseUrl+"products",{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        setUserList(resp.data);
    } catch (error) {
        
    }
}

const handleDelete=async(item)=>{
  setSelectUser(item);

  setAlert(true);
}

const onDelete=async()=>{
  let token=user.jwt;
  try {
    const resp=await axios.delete(baseUrl+"products/"+selectuser.id,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
  setAlert(false);
  successMessage(toast,"Successfully Deleted");
 await retrieveUsers();
} catch (error) {
  errorMessage(toast,error.message);
  setLoading(false);
  return false;
}
}

const handleClose=()=>{
  setAlert(false);
}

  return <div className="userList">
       <Sidebar header={"NISSAGRO"}  menu="products"
      render={
        <div>
            <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/services"
  >
   Services
  </Link>
  <Typography color="text.primary">List</Typography>
</Breadcrumbs>
<div style={{margin:20}}>
    {/* <Link to="/product/add">
<Button variant="contained">Add Product</Button>
</Link> */}
</div>
<div style={{ height: 500, width: '100%' }}>
<DataGrid
        columns={columns}
        disableSelectionOnClick
        rows={userlist}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        pagination
        components={{ Toolbar: GridToolbar }}
      />
    </div>
        </div>
      }
      />
    <AlertDialog  title={"Product Information"} subtitle={"Delete Product Information"} description={"You are about to delete a Product information, Please press agree to proceed"} openAlert={isAlert} handlePress={onDelete} handleClose={handleClose}  />
  </div>
}
