import { Breadcrumbs, Button, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AlertDialog, Sidebar } from '../../components';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { AuthContext } from '../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../env';
import axios from 'axios';
import { logout } from '../../context/authContext/AuthAction';
import { DeleteOutline, UploadFile } from '@mui/icons-material';
import moment from "moment";
import { Link } from "react-router-dom";
import "./soilmappingliststyle.scss";
import { errorMessage, successMessage } from '../../service/function';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import readXlsxFile from 'read-excel-file'

const VISIBLE_FIELDS = ['name', 'email', 'state', 'dateCreated', 'type'];

const Input = styled('input')({
  display: 'none',
});
export default function SoilMappingList() {
   const [userlist,setUserList]=useState([]);
   const [isAlert,setAlert]=useState(false);
   const [selectuser,setSelectUser]=useState({});
   const [loading,setLoading]=React.useState(false);
   const [file,setFile]=React.useState(null);
   const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'state' , headerName: 'state', width: 100, renderCell: (params)=>{
      return (
          <div className="userListUser">
              {params.row?.states?.name}
          </div>
      )
  }},
  { field: 'region' , headerName: 'lga', width: 100, renderCell: (params)=>{
    return (
        <div className="userListUser">
            {params.row?.lga.name}
        </div>
    )
}},
{ field: 'longitude' , headerName: 'longitude', width: 100, renderCell: (params)=>{
  return (
      <div className="userListUser">
          {`${params.row?.longitude}`}
      </div>
  )
}},
{ field: 'latitude' , headerName: 'latitude', width: 100, renderCell: (params)=>{
  return (
      <div className="userListUser">
          {`${params.row?.latitude}`}
      </div>
  )
}},
{ field: 'address' , headerName: 'address', width: 100, renderCell: (params)=>{
  return (
      <div className="userListUser">
          {`${params.row?.address}`}
      </div>
  )
}},
{ field: 'P' , headerName: 'P', width: 50, renderCell: (params)=>{
  return (
      <div className="userListUser">
          {params.row?.data.P}
      </div>
  )
}},
{ field: 'K' , headerName: 'K', width: 50, renderCell: (params)=>{
  return (
      <div className="userListUser">
          {params.row?.data.K}
      </div>
  )
}},
{ field: 'PH' , headerName: 'PH', width: 50, renderCell: (params)=>{
  return (
      <div className="userListUser">
          {params.row?.data.PH}
      </div>
  )
}},
{ field: 'S' , headerName: 'S', width: 50, renderCell: (params)=>{
  return (
      <div className="userListUser">
          {params.row?.data.S}
      </div>
  )
}},
{ field: 'N' , headerName: 'N', width: 50, renderCell: (params)=>{
  return (
      <div className="userListUser">
          {params.row?.data.N}
      </div>
  )
}},
{ field: 'action', headerName: 'Action', width: 100, renderCell: (params)=>{
        return (
            <>
            <Link to={{pathname:"/soil-mapping/edit/"+params.row.id, movie:params.row}} >
             <button className="userListEdit">Edit</button>
             </Link>
             <DeleteOutline className="userListDel" onClick={()=>handleDelete(params.row)}/>
            </>
        )
    } },
  ];
      const [sortModel, setSortModel] = React.useState([
        {
          field: 'id',
          sort: 'desc',
        },
      ]);
const {user,isFetching,dispatch}=useContext(AuthContext);

useEffect(()=>{
    retrieveUsers();
},[])

const retrieveUsers=async()=>{
    let token=user.jwt;
    try {
        const resp=await axios.get(baseUrl+"soil-mappings",{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        setUserList(resp.data);
    } catch (error) {
        
    }
}

const handleDelete=async(item)=>{
  setSelectUser(item);

  setAlert(true);
}

const onDelete=async()=>{
  let token=user.jwt;
  try {
    const resp=await axios.delete(baseUrl+"soil-mappings/"+selectuser.id,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
  setAlert(false);
  successMessage(toast,"Successfully Deleted");
 await retrieveUsers();
} catch (error) {
  errorMessage(toast,error.message);
  setLoading(false);
  return false;
}
}

const handleClose=()=>{
  setAlert(false);
}

const uploadImage = async() => {
  let token=user.jwt;

  readXlsxFile(file).then(async(rows) => {
    let countRow=rows.length;var iterateTotal=0;
    var remainder = countRow % 30;
    var iterate=Math.trunc(countRow/30);
    if(remainder==0){
      iterateTotal=iterate;
    }
    else{
      iterateTotal=iterate+1;
    }
    if(rows[0][0]!=="state"||rows[0][1]!=="lga"||rows[0][2]!=="longitude"||rows[0][3]!=="latitude"||rows[0][4]!=="address"){
      errorMessage(toast,"Please use correct excel file");
      return false;
    }
    for (let i = 0; i < iterateTotal; i++) {
        var formData = {
          json:JSON.stringify(rows.slice(i*30, 30))
        }
      setLoading(true);
      try {
        const resp=await axios.post(baseUrl+"soil-mappings/",formData,{
          headers: {
             Authorization: `Bearer ${token}`
          }
      });
      setLoading(false);
      if(resp.data.status==="success"){
        successMessage(toast,resp.data.message);
        retrieveUsers();
      }
      } catch (error) {
        errorMessage(toast,error.message);
        setLoading(false);
          }
      }
  //   if(countRow<30){
  //     var formData = {
  //       json:JSON.stringify(rows)
  //     }
  //   setLoading(true);
  //   try {
  //     const resp=await axios.post(baseUrl+"members/",formData,{
  //       headers: {
  //          Authorization: `Bearer ${token}`
  //       }
  //   });
  //   setLoading(false);
  //   if(resp.data.status==="success"){
  //     successMessage(toast,resp.data.message);
  //     retrieveUsers();
  //   }
  //   } catch (error) {
  //     errorMessage(toast,error.message);
  //   }
  // }
  // else{
   
  //   }
})
};

  return <div className="userList">
       <Sidebar header={"NISSAGRO"}  menu="soilmapping"
      render={
        <div>
            <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/soil-mapping"
  >
   Soil Mapping
  </Link>
  <Typography color="text.primary">List</Typography>
</Breadcrumbs>
<div style={{margin:20}}>
<Stack direction="row" alignItems="center" spacing={2}>
      <label htmlFor="contained-button-file">
      <TextField label="Import Soil Maps" onChange={e=>setFile(e.target.files[0])} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type={"file"} color="secondary" focused />
      </label>
      <label htmlFor="icon-button-file">
        {/* <Input accept="image/*" id="icon-button-file" type="file" /> */}
        <Button onClick={uploadImage} disabled={loading} variant="contained" component="span">
        {!loading&&<><UploadFile /> Submit</>}
        {loading&& <CircularProgress color="inherit" size={20}  />}
        </Button>
      </label>
    </Stack>
    {/* <Link to="/product/add">
<Button variant="contained"></Button>
</Link> */}
</div>
<div style={{ height: 500, width: '100%' }}>
<DataGrid
        columns={columns}
        disableSelectionOnClick
        rows={userlist}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        pagination
        components={{ Toolbar: GridToolbar }}
      />
    </div>
        </div>
      }
      />
    <AlertDialog  title={"Soil Map Information"} subtitle={"Delete Soil Map Information"} description={"You are about to delete a Soil Map information, Please press agree to proceed"} openAlert={isAlert} handlePress={onDelete} handleClose={handleClose}  />
  </div>;
}
