import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Link,useHistory,} from "react-router-dom";
import {AccountBox, Announcement, AnnouncementOutlined, ChatBubbleOutline, Dashboard, DesignServices, LocalFlorist, LocationCity, MapOutlined, Notifications, Person, PetsOutlined, PowerOff, PowerSettingsNewOutlined, PublishedWithChangesOutlined, Science, ShoppingBasket, Speaker, Timeline} from '@mui/icons-material';
// import { Link } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { logout } from '../context/authContext/AuthAction';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  let history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logOut=()=>{
    logout();
    localStorage.removeItem('@nissagrotoken');
    window.location.href = '/login';
    // history.replace('/login')
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
           {props.header}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Link to="/">
            <ListItem href="/" button selected={props.menu==="dashboard"?true:false} key={"Dashboard"}>
              <ListItemIcon>
               <Dashboard />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItem>
          </Link>    
          
            <ListItem href="/analytics" button selected={props.menu==="analytics"?true:false} key={"Analytics"}>
              <ListItemIcon>
               <Timeline />
              </ListItemIcon>
              <ListItemText primary={"Analytics"} />
            </ListItem>
           
        </List>
        <Divider />
        <List>
        <Link to="/users">
            <ListItem href="/users" selected={props.menu==="users"?true:false} button key={"Users"}>
              <ListItemIcon>
               <Person />
              </ListItemIcon>
              <ListItemText primary={"Users"} />
            </ListItem>
         </Link>
      
            <Link to="/notification">
            <ListItem href="#/notifications" selected={props.menu==="notification"?true:false} button key={"Notification"}>
              <ListItemIcon>
               <Notifications />
              </ListItemIcon>
              <ListItemText primary={"Notification"} />
            </ListItem>
            </Link>
        </List>
        <Divider />
        <List>
        <Link to="/crops">
            <ListItem href="#/crop-library" button selected={props.menu==="crop-library"?true:false} key={"Crop Library"}>
              <ListItemIcon>
               <LocalFlorist />
              </ListItemIcon>
              <ListItemText primary={"Crop Library"} />
            </ListItem>
          </Link>
          <Link to="/livestocks">
            <ListItem href="#/livestock-library" button selected={props.menu==="livestock-library"?true:false} key={"Livestock Library"}>
              <ListItemIcon>
               <PetsOutlined />
              </ListItemIcon>
              <ListItemText primary={"Livestock Library"} />
            </ListItem>
            </Link>
            <Link to="/soil-inspection">
            <ListItem href="#/soil-inspection" button selected={props.menu==="soil-inspection"?true:false} key={"Soil Inspection"}>
              <ListItemIcon>
               <Science />
              </ListItemIcon>
              <ListItemText primary={"Soil Inspection"} />
            </ListItem>
            </Link>
            <Link to="/products">
            <ListItem href="#/services" selected={props.menu==="products"?true:false} button key={"products"}>
              <ListItemIcon>
               <ShoppingBasket/>
              </ListItemIcon>
              <ListItemText primary={"Products"} />
            </ListItem>
            </Link>
            {/* <Link to="/services">
            <ListItem href="#/services" selected={props.menu==="services"?true:false} button key={"services"}>
              <ListItemIcon>
               <DesignServices/>
              </ListItemIcon>
              <ListItemText primary={"Services"} />
            </ListItem>
            </Link> */}
        </List>
        <Divider />
        <List>
            <ListItem href="#/conversation" button selected={props.menu==="conversation"?true:false} key={"Conversation"}>
              <ListItemIcon>
               <ChatBubbleOutline />
              </ListItemIcon>
              <ListItemText primary={"Conversation"} />
            </ListItem>
        </List>
        <Divider />
        <List>
        <Link to="/rss-members">
        <ListItem href="#/services" selected={props.menu==="rss"?true:false} button key={"rss"}>
              <ListItemIcon>
               <AccountBox />
              </ListItemIcon>
              <ListItemText primary={"RSS Members"} />
            </ListItem>
            </Link>
            <Link to="/soil-mapping">
        <ListItem href="#/soil-mapping" selected={props.menu==="soilmapping"?true:false} button key={"rss"}>
              <ListItemIcon>
               <MapOutlined />
              </ListItemIcon>
              <ListItemText primary={"Soil Mapping"} />
            </ListItem>
            </Link>
            <Link to="/offices">
        <ListItem href="#/soil-mapping" selected={props.menu==="office"?true:false} button key={"office"}>
              <ListItemIcon>
               <LocationCity />
              </ListItemIcon>
              <ListItemText primary={"Offices Information"} />
            </ListItem>
            </Link>
            <Link to="/publications">
        <ListItem href="#/publications" selected={props.menu==="publication"?true:false} button key={"publication"}>
              <ListItemIcon>
               <PublishedWithChangesOutlined />
              </ListItemIcon>
              <ListItemText primary={"Publications"} />
            </ListItem>
            </Link>
            <Link to="/announcement">
        <ListItem href="#/announcement" selected={props.menu==="announcement"?true:false} button key={"announcement"}>
              <ListItemIcon>
               <AnnouncementOutlined />
              </ListItemIcon>
              <ListItemText primary={"Announcement"} />
            </ListItem>
            </Link>

        </List>
       
        <Divider />
        <List onClick={logOut}>
            <ListItem onClick={logOut} button key={"Logout"}>
              <ListItemIcon>
               <PowerSettingsNewOutlined />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
       {props.render}
      </Box>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
{/* Same as */}
<ToastContainer />
    </Box>
  );
}
