import React, { useContext, useEffect } from 'react';
import "./adduserstyle.scss";
import { Sidebar,Nigeria } from '../../../components';
import { Breadcrumbs, Button,OutlinedInput, Link, Typography,TextField,Box,option,FormControl,NativeSelect,InputLabel,FormHelperText, IconButton, Input, CircularProgress } from '@mui/material';
import StateData from "../../../State/state.json";
import {PhotoCamera, Send} from "@mui/icons-material"
import { AuthContext } from '../../../context/authContext/AuthContext';
import axios from "axios";
import { baseUrl } from '../../../env';
import { logout } from '../../../context/authContext/AuthAction';
import { ToastContainer, toast } from 'react-toastify';
import { errorMessage, successMessage, warningMessage } from '../../../service/function';
import { useParams } from 'react-router-dom';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
export default function AddUser() {
    const [fields, setFields] = React.useState({});
    const [service, setService] = React.useState([]);
    const [language, setLanguage] = React.useState([]);
    const [loading,setLoading]=React.useState(false);
    const [state,setState]=React.useState(null);
    const [lga,setLGA]=React.useState(null);
    const [jsonlga,setJsonLGA]=React.useState([]);
    const [image,setImage]=React.useState(null);
    const {user,isFetching,dispatch}=useContext(AuthContext);
    let { id } = useParams();


    useEffect(()=>{
        retrieveUserDetail();
    },[]);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setService(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleLanguage = (event) => {
      const {
        target: { value },
      } = event;
      setLanguage(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const changeState=(e)=>{
      setState(JSON.parse(e.target.value).name);
      setJsonLGA(JSON.parse(e.target.value).lgas);
    //   console.log(e);
  }

  const changeLga=(e)=>{
      setLGA(e.target.value);
  }

  const retrieveUserDetail=async()=>{
    let token=user.jwt;
    try {
      const respbusiness=await axios.get(baseUrl+"businesses/"+id,{
        headers: {
           Authorization: `Bearer ${token}`
        }
        
    });
        const respuser=await axios.get(baseUrl+"users/"+respbusiness.data.user.id,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
       
        let userBusiness = {
            ...respuser.data,
            ...respbusiness.data
        };
        setFields(userBusiness);
        setState(userBusiness.state);
       StateData.find(function (element) {
            if(element.name ===fields["live_state"] ){
                setJsonLGA(element.lgas);
            }
        });
      
    } catch (error) {
        
    }
}

  const handleChangeInput=(e)=>{
    const value= e.target.value;
    setFields({...fields, [e.target.name]:value});
  }
 
  const uploadImage = async(token,image) => {
    var formData = new FormData();
    setLoading(true);
    formData.append('files',image);
    const resp=await axios.post(baseUrl+"upload/",formData,{
        headers: {
           Authorization: `Bearer ${token}`,
           'Content-Type': 'multipart/form-data'
        }
    })
    console.log(resp.data[0])
    return resp.data[0];
    
};

  const sendData=async(e)=>{
    e.preventDefault();
    let token=user.jwt;
    
    try {
        if(image){
            let imageId=await uploadImage(token,image);
            fields['avatar']=imageId.id
        }
      fields['state']=state;
      fields['lga']=lga;
    if(service.length>0){
      fields['service']=service.toString();
    }
    if(language.length>0){
      fields['language']=language.toString();
    }
      setLoading(true);
      const resp=await axios.post(baseUrl+'businesses/admin/update/'+id,fields,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    if(resp.data.status==="error"){
      errorMessage(toast,resp.data.message);
      setLoading(false)
      return false;
    }
    successMessage(toast,resp.data.message);
    // document.getElementById("userForm").reset();
    setLoading(false)
    } catch (error) {
      errorMessage(toast,error.message);
      setLoading(false)
    }
  }

  return  <div >
  <Sidebar header={"NISSAGRO "}
  menu="users"
      render={
         <div >
             <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/users"
  >
    User List
  </Link>
  <Typography color="text.primary">Edit User</Typography>
</Breadcrumbs>
 <div >
    <h4>Edit User Information</h4>
<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
       <form id="userForm" onSubmit={e => e.preventDefault()}>
       <div style={{display:'flex',flexDirection:'row'}}>
        <TextField
          required
          name="name"
          id="outlined-required"
          label={"Name"}
          variant="filled"
          value={fields["name"]}
          onChange={handleChangeInput}
        />
        <TextField
        required
          id="outlined-required"
          name="phone"
          label="Mobile Number"
          type="text"
          variant="filled"
          onChange={handleChangeInput}
          value={fields["phone"]}
        />
        <TextField
        required
          id="outlined-password-input"
          name="password"
          label="Password"
          value={""}
          type="password"
          variant="filled"
          onChange={handleChangeInput}
        />
        <TextField
        required
          id="outlined-required"
          name="email"
          label="Email"
          value={fields["email"]}
          type="email"
          variant="filled"
          onChange={handleChangeInput}
        />
       
      </div>
       <div style={{display:'flex',flexDirection:'row'}}>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
        <NativeSelect
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Role"
          value={fields["user_type"]}
          name="role"
          variant="filled"
          onChange={handleChangeInput}
        >
          <option value="">
            
          </option>
          <option value={'farmer'} selected={fields["user_type"]==="farmer"?true:false}>Farmer</option>
          <option value={'rssmember'} selected={fields["user_type"]==="rssmember"?true:false}>RSS Member</option>
          <option value={'extensionagent'} selected={fields["user_type"]==="extentionagent"?true:false}>Extention Agent</option>
          <option value={'agrodealer'} selected={fields["user_type"]==="agrodealer"?true:false}>Agro Dealer</option>
          <option value={'government'} selected={fields["user_type"]==="government"?true:false}>Government Agent</option>
          <option value={'scholarsngo'} selected={fields["user_type"]==="scholarsngo"?true:false}>Scholar/NGO</option>
          <option value={'researcher'} selected={fields["user_type"]==="researcher"?true:false}>Researcher</option>
          <option value={'others'} selected={fields["user_type"]==="others"?true:false}>Others</option>
        </NativeSelect>
        <FormHelperText>Choose user role</FormHelperText>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
        <NativeSelect
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Gender"
          alue={fields["gender"]}
          name="gender"
          variant="filled"
          onChange={handleChangeInput}
        >
          <option value="">
            
          </option>
          <option value={'Male'} selected={fields["gender"]==="Male"?true:false}>Male</option>
          <option value={'Female'} selected={fields["gender"]==="Female"?true:false}>Female</option>
        </NativeSelect>
        <FormHelperText>Choose user gender</FormHelperText>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Location State ({fields["live_state"]})</InputLabel>
        <NativeSelect
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Location"
          name="state"
          onChange={changeState}
        >
          <option value="">
            
          </option>
         {StateData.map((item,i)=>(
            <option key={i} value={JSON.stringify(item)} selected={fields["live_state"]===item.name?true:false} >{item.name}</option>
         ))}
        </NativeSelect>
        <FormHelperText>Choose user location</FormHelperText>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Location LGA ({fields["live_lga"]})</InputLabel>
        <NativeSelect
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Location"
          variant="filled"
          name="lga"
          onChange={changeLga}
        >
          <option value={fields["live_lga"]}>
            {fields["live_lga"]}
          </option>
          {jsonlga.map((item,i)=>(
            <option key={i} value={item.name} selected={fields["live_lga"]===item.name?true:false}>{item.name}</option>
         ))}
        </NativeSelect>
        <FormHelperText>Choose user location</FormHelperText>
      </FormControl>
      </div>
       <div style={{display:'flex',flexDirection:'row'}}>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Service</InputLabel>
        <NativeSelect
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          variant="filled"
          multiple
          input={<OutlinedInput label="Name" />}
          defaultValue={service}
          MenuProps={MenuProps}
          onChange={handleChange}
        >
          <option value="">
           {fields["service"]}
          </option>
          <option value={'Soil Survey'}>Soil Survey</option>
          <option value={'Soil Sampling'}>Soil Sampling</option>
          <option value={'Advisory Service'}>Advisory Service</option>
          <option value={'Technical Service'}>Technical Service</option>
          <option value={'Laboratory Service'}>Laboratory Service</option>
          <option value={'Machinery Service'}>Machinery Service</option>
          <option value={'Pest and Disease'}>Pest and Disease</option>
          <option value={'Fertilizer Usage and Policy'}>Fertilizer Usage and Policy</option>
          <option value={'Farm Labour'}>Farm Labour</option>
          <option value={'Research'}>Research</option>
        </NativeSelect>
        <FormHelperText>Choose user service</FormHelperText>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Language</InputLabel>
        <NativeSelect
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          input={<OutlinedInput label="Name" />}
          defaultValue={language}
          MenuProps={MenuProps}
          variant="filled"
          onChange={handleLanguage}
        >
          <option value="">
          {fields["language"]}
          </option>
          <option value={'English'}>English</option>
          <option value={'Hausa'}>Hausa</option>
          <option value={'Yoruba'}>Yoruba</option>
          <option value={'Igbo'}>Igbo</option>
        </NativeSelect>
        <FormHelperText>Choose user languages</FormHelperText>
      </FormControl>
      <TextField
          id="outlined-password-input"
          name="business_address"
          label="Business Address"
          variant="filled"
          type="text"
          onChange={handleChangeInput}
          value={fields["business_address"]}
        />
          <TextField
          id="outlined-password-input"
          name="business_contact"
          label="Business Contact"
          variant="filled"
          type="text"
          value={fields["business_contact"]}
          onChange={handleChangeInput}
        />
      </div>
       <div style={{display:'flex',flexDirection:'row'}}>
      <TextField
          id="outlined-password-input"
          name="business_email"
          label="Business Email"
          variant="filled"
          type="text"
          value={fields["business_email"]}
          onChange={handleChangeInput}
        />
          <TextField
          id="outlined-password-input"
          name="register_no"
          label="Registration Number"
          type="text"
          variant="filled"
          value={fields["register_no"]}
          onChange={handleChangeInput}
        />
         <TextField
          id="outlined-password-input"
          name="facebook"
          label="Facebook Address"
          value={fields["facebook"]}
          variant="filled"
          type="text"
          onChange={handleChangeInput}
        />
          <TextField
          id="outlined-password-input"
          name="geolocation"
          label="Business Coordinate"
          placeholder='long:7.02344,Lat:4.0983'
          variant="filled"
          value={fields["geolocation"]}
          type="text"
          onChange={handleChangeInput}
        />
      </div>
      <div style={{display:'flex',flexDirection:'row'}}>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Account Status</InputLabel>
        <NativeSelect
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          variant="filled"
          label="Status"
          defaultValue={fields["blocked"]}
          name="blocked"
          onChange={handleChangeInput}
        >
          <option value="">
            
          </option>
          <option value={0} selected={fields["blocked"]==0||fields["blocked"]==null?true:false}>Active</option>
          <option value={1} selected={fields["blocked"]==1?true:false}>Block</option>
        </NativeSelect>
        <FormHelperText>Choose account status</FormHelperText>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Verification Status</InputLabel>
        <NativeSelect
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          variant="filled"
          label="Verification Status"
          defaultValue={fields["confirm"]}
          name="confirm"
          onChange={handleChangeInput}
        >
          <option value="">
            
          </option>
          <option value={0} selected={fields["confirm"]==0||fields["confirm"]==null?true:false}>Not Verified</option>
          <option value={1} selected={fields["confirm"]==1?true:false}>Verified</option>
        </NativeSelect>
        <FormHelperText>Choose verification status</FormHelperText>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Can Reply Chat</InputLabel>
        <NativeSelect
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          variant="filled"
          label="Reply Chat"
          defaultValue={fields["chat"]}
          name="chat"
          onChange={handleChangeInput}
        >
          <option value="">
            
          </option>
          <option value={0} selected={fields["chat"]==0||fields["chat"]==null?true:false}>No</option>
          <option value={1} selected={fields["chat"]==1?true:false}>Yes</option>
        </NativeSelect>
        <FormHelperText>Choose Chat Reply</FormHelperText>
      </FormControl>
      <label htmlFor="icon-button-file">
         <p> Choose Image</p>
            <Input accept="image/*" onChange={e=>setImage(e.target.files[0])} id="icon-button-file" type="file" />
            <IconButton color="primary" aria-label="upload picture" component="span">
                <PhotoCamera />
            </IconButton>
            </label>

      </div>
      <div style={{margin:20}}>
      <Button onClick={sendData} disabled={loading} variant="contained" endIcon={<Send />}>
      {!loading&& <>Submit</>}
   {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
      </form>
    </Box>
</div>
        </div>
      }
      />
  </div>;
}
