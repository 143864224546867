import React, { useContext, useEffect } from 'react';
import "./addsoilstyle.scss";
import { Sidebar,Nigeria } from '../../../components';
import { Breadcrumbs, Button,OutlinedInput, Link, Typography,TextField,Box,option,FormControl,NativeSelect,InputLabel,FormHelperText, IconButton, Input } from '@mui/material';
import StateData from "../../../State/state.json";
import {PhotoCamera, Send} from "@mui/icons-material"
import { AuthContext } from '../../../context/authContext/AuthContext';
import axios from "axios";
import { baseUrl } from '../../../env';
import { logout } from '../../../context/authContext/AuthAction';
import { ToastContainer, toast } from 'react-toastify';
import { errorMessage, successMessage, warningMessage } from '../../../service/function';
import { useParams } from 'react-router-dom';
import { Editor } from "react-draft-wysiwyg";
import { EditorState,convertToRaw,ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
export default function EditSoil() {
    const [fields, setFields] = React.useState({});
    const [service, setService] = React.useState([]);
    const [language, setLanguage] = React.useState([]);
    const [loading,setLoading]=React.useState(false);
    const [state,setState]=React.useState(null);
    const [lga,setLGA]=React.useState(null);
    const [name,setName]=React.useState(null);
    const [image,setImage]=React.useState(null);
    const {user,isFetching,dispatch}=useContext(AuthContext);
    const [about,setAbout]=React.useState(null);
    const [aboutYor,setAboutYor]=React.useState(null);
    const [aboutHau,setAboutHau]=React.useState(null);
    const [aboutIgb,setAboutIgb]=React.useState(null);
    const [plantation,setPlantation]=React.useState(null);
    const [plantationYor,setPlantationYor]=React.useState(null);
    const [plantationHau,setPlantationHau]=React.useState(null);
    const [plantationIgb,setPlantationIgb]=React.useState(null);
    const [deficiency,setDeficiency]=React.useState(null);
    const [deficiencyYor,setDeficiencyYor]=React.useState(null);
    const [deficiencyHau,setDeficiencyHau]=React.useState(null);
    const [deficiencyIgb,setDeficiencyIgb]=React.useState(null);
    const [advice,setAdvice]=React.useState(null);
    const [adviceYor,setAdviceYor]=React.useState(null);
    const [adviceHau,setAdviceHau]=React.useState(null);
    const [adviceIgb,setAdviceIgb]=React.useState(null)
    let { id } = useParams();


    useEffect(()=>{
        retrieveUserDetail();
    },[]);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setService(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleLanguage = (event) => {
      const {
        target: { value },
      } = event;
      setLanguage(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

   const onEditorStateChange = (editorState,val) => {
     val(editorState);
    };

  const changeLga=(e)=>{
      setLGA(e.target.value);
  }

  const retrieveUserDetail=async()=>{
    let token=user.jwt;
    try {
        const respcrop=await axios.get(baseUrl+"soil-inspections/"+id,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
        setFields(respcrop.data);
        setAbout(respcrop.data.about?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.about).contentBlocks)):null);
        setAboutYor(respcrop.data.aboutYoruba?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.aboutYoruba).contentBlocks)):null);
        setAboutHau(respcrop.data.aboutHausa?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.aboutHausa).contentBlocks)):null);
        setAboutIgb(respcrop.data.aboutIgbo?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.aboutIgbo).contentBlocks)):null);
        setPlantation(respcrop.data.about?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.plantation).contentBlocks)):null);
        setPlantation(respcrop.data.plantation?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.plantation).contentBlocks)):null);
        setPlantationYor(respcrop.data.plantationYoruba?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.plantationYoruba).contentBlocks)):null);
        setPlantationHau(respcrop.data.plantationHausa?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.plantationHausa).contentBlocks)):null);
        setPlantationIgb(respcrop.data.plantationIgbo?EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(respcrop.data.plantationIgbo).contentBlocks)):null);
        
      } catch (error) {
        
    }
}

  const handleChangeInput=(e)=>{
    const value= e.target.value;
    setFields({...fields, [e.target.name]:value});
  }
 
  const uploadImage = async(token,image) => {
    var formData = new FormData();
    setLoading(true);
    formData.append('files',image);
    const resp=await axios.post(baseUrl+"upload/",formData,{
        headers: {
           Authorization: `Bearer ${token}`,
           'Content-Type': 'multipart/form-data'
        }
    })
    console.log(resp.data[0])
    return resp.data[0];
    
};

  const sendData=async(e,data)=>{
    e.preventDefault();
    let token=user.jwt;
    
    try {
        if(image){
            let imageId=await uploadImage(token,image);
            fields['image']=imageId.id
        }
        delete fields["state"];
        delete fields["crop_libraries"];
      fields[Object.keys(data)[0]]=draftToHtml(convertToRaw(data[Object.keys(data)[0]].getCurrentContent()));
      setLoading(true);
      const resp=await axios.put(baseUrl+'soil-inspections/'+id,fields,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    if(resp.data.status==="error"){
      errorMessage(toast,resp.data.message);
      return false;
    }
    successMessage(toast,resp.data.message);
    // document.getElementById("userForm").reset();
    setLoading(false)
    } catch (error) {
      
    }
  }

  return  <div >
  <Sidebar header={"NISSAGRO "}
  menu="soil-inspection"
      render={
         <div >
             <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/soil-inspection"
  >
   Soil Inspection List
  </Link>
  <Typography color="text.primary">Edit Soil Inspection</Typography>
</Breadcrumbs>
 <div >
    <h4>Edit Soil Inspection Information</h4>
<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
       <form id="userForm" onSubmit={e => e.preventDefault()}>
      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
           <Typography>About Soil Inspection ({fields.state?.name})</Typography>
           </AccordionSummary>
      <div style={{display:'flex',flexDirection:'column'}}>
      <h4>About Soil Inspection (English)</h4>
      <Editor
  editorState={about}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAbout)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'about':about})} variant="contained" endIcon={<Send />}>
 Update About Soil Inspection (English)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>About Soil Inspection (Hausa)</h4>
      <Editor
  editorState={aboutHau}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAboutHau)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'aboutHausa':aboutHau})} variant="contained" endIcon={<Send />}>
 Update About Soil Inspection (Hausa)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>About Soil Inspection (Yoruba)</h4>
      <Editor
  editorState={aboutYor}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAboutYor)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'aboutYoruba':aboutYor})} variant="contained" endIcon={<Send />}>
 Update About Soil Inspection (Yoruba)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>About Soil Inspection (Igbo)</h4>
      <Editor
  editorState={aboutIgb}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setAboutIgb)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'aboutIgbo':aboutIgb})} variant="contained" endIcon={<Send />}>
 Update About Soil Inspection (Igbo)
</Button>
      </div>
</div>
</Accordion>
<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
           <Typography>Plantation Soil Inspection ({fields.state?.name})</Typography>
           </AccordionSummary>
      <div style={{display:'flex',flexDirection:'column'}}>
      <h4>Plantation Soil Inspection (English)</h4>
      <Editor
  editorState={plantation}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setPlantation)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'plantation':plantation})} variant="contained" endIcon={<Send />}>
 Update Plantation Soil Inspection (English)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>About Soil Inspection (Hausa)</h4>
      <Editor
  editorState={plantationHau}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setPlantationHau)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'plantationHausa':plantationHau})} variant="contained" endIcon={<Send />}>
 Update Plantation Soil Inspection (Hausa)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>Plantation Soil Inspection (Yoruba)</h4>
      <Editor
  editorState={plantationYor}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setPlantationYor)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'plantationYoruba':plantationYor})} variant="contained" endIcon={<Send />}>
 Update Plantation Soil Inspection (Yoruba)
</Button>
      </div>
</div>
<div style={{display:'flex',flexDirection:'column'}}>
      <h4>Plantation Soil Inspection (Igbo)</h4>
      <Editor
  editorState={plantationIgb}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(e)=>onEditorStateChange(e,setPlantationIgb)}
/>
<div style={{margin:20}}>
      <Button onClick={(e)=>sendData(e,{'plantationIgbo':plantationIgb})} variant="contained" endIcon={<Send />}>
 Update Plantation Soil Inspection (Igbo)
</Button>
      </div>
</div>
</Accordion>
      </form>
    </Box>
</div>
        </div>
      }
      />
  </div>;
}

