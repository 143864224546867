import React, { useContext,useEffect } from 'react';
import "./addproductstyle.scss";
import { Sidebar,Nigeria } from '../../../components';
import { Breadcrumbs, Button,OutlinedInput, Link, Typography,TextField,Box,MenuItem,FormControl,Select,InputLabel,FormHelperText, CircularProgress, IconButton, Input } from '@mui/material';
import StateData from "../../../State/state.json";
import {PhotoCamera, Send} from "@mui/icons-material"
import { AuthContext } from '../../../context/authContext/AuthContext';
import axios from "axios";
import { baseUrl } from '../../../env';
import { logout } from '../../../context/authContext/AuthAction';
import { ToastContainer, toast } from 'react-toastify';
import { errorMessage, successMessage, warningMessage } from '../../../service/function';
import moment from "moment";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
export default function AddProduct() {
    const [fields, setFields] = React.useState({});
    const [service, setService] = React.useState([]);
    const [language, setLanguage] = React.useState([]);
    const [category, setCategory] = React.useState([]);
    const [loading,setLoading]=React.useState(false);
    const [state,setState]=React.useState(null);
    const [jsonlga,setJsonLGA]=React.useState([]);
    const [image,setImage]=React.useState(null);
    const {user,isFetching,dispatch}=useContext(AuthContext);

  useEffect(()=>{
    getCategory();
  },[])

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setService(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleLanguage = (event) => {
      const {
        target: { value },
      } = event;
      setLanguage(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const changeState=(e)=>{
      setState(JSON.parse(e.target.value).name);
      setJsonLGA(JSON.parse(e.target.value).lgas);
      console.log(e);
  }

  const getCategory=async()=>{
    let token=user.jwt;
    let resp=await axios.get(baseUrl+`product-categories`,{
      headers: {
         Authorization: `Bearer ${token}`
      }
  });
  setCategory(resp.data);
  }

  const handleChangeInput=(e)=>{
    const value= e.target.value;
    setFields({...fields, [e.target.name]:value});
  }

  const uploadImage = async(token,image) => {
    var formData = new FormData();
    setLoading(true);
    formData.append('files',image);
    const resp=await axios.post(baseUrl+"upload/",formData,{
        headers: {
           Authorization: `Bearer ${token}`,
           'Content-Type': 'multipart/form-data'
        }
    })
    console.log(resp.data[0])
    return resp.data[0];
    
};

  const sendData=async(e)=>{
    e.preventDefault();
    let token=user.jwt;
    try {
      if(!fields["name"]||!fields["description"]||!fields["product_category"]){
        warningMessage(toast,"Please fill all required fields");
      return false;
      }
      if(image){
        let imageId=await uploadImage(token,image);
        fields['image']=imageId.id
    }
      // fields["uuid"]=moment().format("x");
      setLoading(true);
      const resp=await axios.post(baseUrl+'products',fields,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    successMessage(toast,"Successfully Submitted");
    document.getElementById("userForm").reset();
    setFields({});
    setLoading(false);
    } catch (error) {
      errorMessage(toast,error.message);
      setLoading(false);
      return false;
    }
  }

  return <div>
  <Sidebar header={"NISSAGRO "}
  menu="products"
      render={
        <div>
             <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/products"
  >
    Product List
  </Link>
  <Typography color="text.primary">Add Product</Typography>
</Breadcrumbs>
<div>
    <h4>Add New Product</h4>
<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
       <form id="userForm" onSubmit={e => e.preventDefault()}>
       <div style={{display:'flex',flexDirection:'row'}}>
        <TextField
          required
          name="name"
          id="outlined-required"
          label="Name"
          value={fields["name"]}
          onChange={handleChangeInput}
        />
         <TextField
          name="company"
          id="outlined-required"
          label="Company"
          value={fields["company"]}
          onChange={handleChangeInput}
        />
         <TextField
         placeholder='http://example.com'
          name="url"
          id="outlined-required"
          label="Company Url"
          value={fields["url"]}
          onChange={handleChangeInput}
        />
        <TextField
        
          name="phone"
          id="outlined-required"
          label="Company Mobile Number"
          value={fields["phone"]}
          onChange={handleChangeInput}
        />
      </div>
     <div style={{display:'flex',flexDirection:'row'}}>
     <label htmlFor="icon-button-file">
         <p> Choose Product image</p>
            <Input accept="image/*" onChange={e=>setImage(e.target.files[0])} id="icon-button-file" type="file" />
            <IconButton color="primary" aria-label="upload picture" component="span">
                <PhotoCamera />
            </IconButton>
            </label>
    
     <FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Choose Category</InputLabel>
        <Select
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Category"
          defaultValue={fields["product_category"]}
          name="product_category"
          onChange={handleChangeInput}
        >
           
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {category.map((item,i)=>(
             <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText>Choose product category</FormHelperText>
      </FormControl>
     <TextField
          name="description"
          id="outlined-required"
          multiline={true}
          rows={4}
          label="Description"
          value={fields["description"]}
          onChange={handleChangeInput}
        />
     </div>
     
      <div style={{margin:20}}>
      <Button onClick={sendData} disabled={loading} variant="contained" endIcon={<Send />}>
      {!loading&& <>Submit</>}
   {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
      </form>
    </Box>
</div>
        </div>
      }
      />
  </div>;
}
