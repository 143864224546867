import { Breadcrumbs, Button, Typography,FormControl,OutlinedInput,MenuItem,Select,InputLabel,FormHelperText, CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AlertDialog, Sidebar } from '../../components';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { AuthContext } from '../../context/authContext/AuthContext';
import { baseUrl, imageUrl } from '../../env';
import axios from 'axios';
import { logout } from '../../context/authContext/AuthAction';
import { Add, DeleteOutline, Send } from '@mui/icons-material';
import moment from "moment";
import { Link } from "react-router-dom";
import "./userliststyle.scss";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { errorMessage, successMessage } from '../../service/function';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function UserList() {
   const [userlist,setUserList]=useState([]);
   const [isAlert,setAlert]=useState(false);
   const [selectuser,setSelectUser]=useState({});
   const [open ,setOpen]=useState(false);
   const [fields,setFields]=useState([]);
   const [loading,setLoading]=useState(false);
   const [idUser,setIdUser]=useState(null)
   const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'name' , headerName: 'Name', width: 180, renderCell: (params)=>{
        return (
            <div className="userListUser">
                <img className="userListImg" src={params.row.user&&params.row.user.avatar?imageUrl+params.row.user.avatar.url:"/avatar.png"} alt="" />
                {params.row.name}
            </div>
        )
    }},
    { field: 'service', headerName: 'Services', width: 180 },
    { field: 'live_state', headerName: 'State', width: 180 },
    { field: 'business', headerName: 'State', width: 100, renderCell: (params)=>{
        return (
           <b>{params.row?.live_state}</b>
        )
    }},
    { field: 'created_at',headerName: 'Created Date', width: 180 , renderCell: (params)=>{
        return (
            <>
            <p>{moment(params.row.created_at).format("LL")}</p>
            </>
            )
    }},
    { field: 'business',headerName: 'Role', type: 'text', width: 100 , renderCell: (params)=>{
        return (
           <b>{params.row?.business_type}</b>
        )
    }},
    { field: 'action', headerName: 'Action', width: 250, renderCell: (params)=>{
        return (
            <>
            <Link to={{pathname:"/user/edit/"+params.row.id, movie:params.row}} >
             <button className="userListEdit">Edit</button>
             </Link>
             {/* <button onClick={()=>openModal(params.row)} className="userListService"><Add /> Services</button> */}
             <DeleteOutline className="userListDel" onClick={()=>handleDelete(params.row)}/>
            </>
        )
    } },
  ];
      const [sortModel, setSortModel] = React.useState([
        {
          field: 'id',
          sort: 'desc',
        },
      ]);
const {user,isFetching,dispatch}=useContext(AuthContext);

useEffect(()=>{
    retrieveUsers();
},[])

const retrieveUsers=async()=>{
    let token=user.jwt;
    try {
        const resp=await axios.get(baseUrl+`businesses`,{
            headers: {
               Authorization: `Bearer ${token}`
            }
        });
    setUserList(resp.data);
   
    } catch (error) {
      errorMessage(toast,error.message);
      setLoading(false); 
    }
}

const handleDelete=async(item)=>{
  setSelectUser(item);

  setAlert(true);
}

const onDelete=async()=>{
  let token=user.jwt;
  try {
    const resp=await axios.delete(baseUrl+"users/"+selectuser.id,{
        headers: {
           Authorization: `Bearer ${token}`
        }
    });
    if(selectuser.business){
    const respd=await axios.delete(baseUrl+"businesses/"+selectuser.business.id,{
      headers: {
         Authorization: `Bearer ${token}`
      }
  });
}
  setAlert(false);
  successMessage(toast,"Successfully deleted")
 await retrieveUsers();
} catch (error) {
  errorMessage(toast,error.message);
    setLoading(false);
}
}

const sendData=async(e)=>{
  e.preventDefault();
  let token=user.jwt;
  try {
    let datat={
      service:fields.toString()
    }
    setLoading(true);
    const resp=await axios.put(baseUrl+`businesses/${idUser.id}`,datat,{
      headers: {
         Authorization: `Bearer ${token}`
      }
  });
  successMessage(toast,"Successfully updated");
  setOpen(false);
  setLoading(false);
  } catch (error) {
    errorMessage(toast,error.message);
    setLoading(false);
  }
}

const handleChange = (event) => {
  const {
    target: { value },
  } = event;
  setFields(
    // On autofill we get a stringified value.
    typeof value === 'string' ? value.split(',') : value,
  );
};

const handleClose=()=>{
  setAlert(false);
}

const handleCloseModal=()=>{
  setOpen(false);
}

const openModal=(data)=>{
  setFields(data.service?data.service.split(","):[]);
  setOpen(true);
  setIdUser(data);
}

  return <div className="userList">
       <Sidebar header={"NISSAGRO Admin"}  menu="users"
      render={
        <div>
            <Breadcrumbs aria-label="breadcrumb">
  <Link underline="hover" color="inherit" href="/">
    Dashboard
  </Link>
  <Link
    underline="hover"
    color="inherit"
    href="/users/"
  >
    User
  </Link>
  <Typography color="text.primary">List</Typography>
</Breadcrumbs>
<div style={{margin:20}}>
    <Link to="/user/add">
<Button variant="contained">Add User</Button>
</Link>
</div>
<div style={{ height: 500, width: '100%' }}>
        <DataGrid
        columns={columns}
        disableSelectionOnClick
        rows={userlist}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        pagination
        components={{ Toolbar: GridToolbar }}
      />
    </div>
  </div>
      }
      />
    <AlertDialog  title={"User Information"} subtitle={"Delete "+selectuser.name+" Information"} description={"You are about to delete a User information, Please press agree to proceed"} openAlert={isAlert} handlePress={onDelete} handleClose={handleClose}  />
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
        
            
<Box
      component="form"
      sx={style}
      noValidate
      autoComplete="off"
    >
      <h4>Add Service</h4>
       <form id="userForm" onSubmit={e => e.preventDefault()}>
<div>
<FormControl sx={{ m: 1, minWidth: 225 }}>
        <InputLabel id="demo-simple-select-helper-label">Select Services</InputLabel>
        <Select
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Role"
          multiple
          value={fields}
          name="role"
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'Soil Survey'}>Soil Survey</MenuItem>
          <MenuItem value={'Soil Sampling'}>Soil Sampling</MenuItem>
          <MenuItem value={'Advisory Service'}>Advisory Service</MenuItem>
          <MenuItem value={'Technical Service'}>Technical Service</MenuItem>
          <MenuItem value={'Laboratory Service'}>Laboratory Service</MenuItem>
          <MenuItem value={'Machinery Service'}>Machinery Service</MenuItem>
          <MenuItem value={'Pest and Disease'}>Pest and Disease</MenuItem>
          <MenuItem value={'Fertilizer Usage and Policy'}>Fertilizer Usage and Policy</MenuItem>
          <MenuItem value={'Farm Labour'}>Farm Labour</MenuItem>
          <MenuItem value={'Research'}>Research</MenuItem>
        </Select>
        <FormHelperText>Choose user role</FormHelperText>
      </FormControl>
</div>
<div style={{margin:20}}>
      <Button onClick={sendData} disabled={loading} variant="contained" endIcon={<Send />}>
      {!loading&& <>Submit</>}
   {loading&& <CircularProgress color="inherit" size={20}  />}
</Button>
      </div>
        </form>
          </Box>
        </Fade>
      </Modal>
  </div>;
}
